
@media screen and (max-width: 992px) {
	#blockcart-modal {
		.modal-footer {
			flex-direction: row;
		}
	}
}

@media screen and (max-width: 769px) {
	
	.checkout-process {
		.nav-tabs {
			flex-wrap: wrap;
			overflow: visible;
			
			.nav-item {
				flex: 0 0 100%;
				text-align: center;
			}
		}
	}

	#blockcart-modal {
		.modal-footer {
			padding-bottom: 2rem;
		}
	}

	.checkout-step {
		.continue {
			display: block;
			width: 100%;
		}
	}

}
