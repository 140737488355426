.l-header {
	background-color: $header-bg;
	margin-bottom: $spacer;
	box-shadow: $header-box-shadow;
}

.header__up {
	align-content: center;
}

.header-top {
	flex-wrap: wrap;
	padding-top: $spacer*1.25;
	padding-bottom: $spacer*1.25;
}

.header__search {
	flex: 1;
	padding-right: $grid-gutter-width/2;
	padding-left: $grid-gutter-width/2;
}

.header__right,
.header-nav__right {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.blockcart,
.user-info {
	a {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;
	}

	i {
		flex: 0 0 100%;
		font-size: 36px;
	}

}

.header__rightitem:not(:last-child) {
	margin-right: $spacer/2;
}

.header__logo--checkout {
	max-width: 200px;
}

.header-nav--checkout {
	padding-top: $spacer;
	padding-bottom: $spacer;
}

@include media-breakpoint-mobile {
	.header__logo,
	.header__logo--checkout {
		max-width: 50%;
	}
	/*.header__search {
		width: 100%;
		padding: 0;
		flex: auto;
		order: 10;
		margin-top: 10px;
	}*/
	.header__logo--checkout {
		margin: 0 auto;
	}
}

@include media-breakpoint-desktop() {
	.header-top__col {
		width: 25%;
	}
}

@include media-breakpoint-up(md) {
	.header-top__col {
		width: 25%;
	}
}

#header-sticky {
	position: relative;
	z-index: $zindex-sticky;
	top: auto;
	right: 0;
	left: 0;

	&.sticky {
		.container-menu{
			position: fixed;
			top: 0;
			background: #fff;
			box-shadow: 0 0 20px rgba(#000, 0.15);
			width: 100%;
			max-width: 100%;
		}

	}
}

@media screen and (min-width: 1200px) {
	#header {
		min-height: 308px;

		.sticky {
			.header-top__col {
				img {
					max-height: 37.5px;
					width: auto;
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	#header {
		min-height: 138px;
	}
	/*#header-sticky {
		&.sticky {
			background: rgba(#fff, 0.85);
			backdrop-filter: blur(5px);
		}
	}*/
}

@media (min-width: 992px){
	#header-sticky{
		.user-info {
			&:hover{
				ul{
					display: block!important;
					position: absolute;
					z-index: 10;
					padding: 10px;
					box-shadow: 0 0 16px rgba(0 ,0 ,0 , 0.10);
					background: #fff;
					overflow: hidden;
				}
			}

		}
	}
}

#index #header{
	margin-bottom: 0;
}


#header{
	.header-banner{
		position: relative;
		z-index: 1021;
	}
	.header__search{
		text-align: center;
		.logo{
			margin-bottom: 20px;
		}
	}
	#search_widget{
		float: none;
		max-width: 460px;
		margin: 0 auto;
		input{
			border: 1px solid #001253;
			font-weight: normal;
			font-size: 14px;
			text-align: left;
			color: #001253;
			padding: 5px 40px 5px 20px;
			border-radius: 30px;
			background: #fff;
			&::placeholder{
				color: #001253;
			}
		}
		i.search{
			right: 10px;
			top: 50%;
			font-size: 0;
			transform: translateY(-50%);
			width: 19px;
			height: 19px;
			background-image: url("../img/np_magic.svg");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
	.ek_infos-container{
		background: #001253;
		p , *{
			font-weight: 600;
			font-size: 14px;
			text-align: center;
			color: #fff;
		}
		.caption{
			> div{
				display: flex;
				align-items: center;
				justify-content: center;
				&:before , &:after{
					content: "";
					width: 10px;
					height: 10px;
					display: inline-block;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
					background-image: url("../img/pseudo_nav.svg");
					margin: 0 10px;
				}
			}
		}
		.slick-slide{
			padding: 5px;
		}
	}
	.header-top{
		padding: 40px 15px;
		align-items: end!important;
		.header_left{
			padding-right: 50px;
			.content{
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: end;
				.address_header{
					margin-bottom: 30px;
					font-weight: normal;
					font-size: 14px;
					color: #001253;
					display: flex;
					align-items: center;
					position: relative;
					&:before{
						content:"";
						width: 440px;
						height: 440px;
						background-image: url("../img/before_header.png");
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center;
						position: absolute;
						left: 0;
						transform: translate(-82%, -4%);
					}
					img{
						margin-right: 10px;
					}
				}
				.horraires_header{
					margin-right: 50px;
					font-weight: normal;
					font-size: 14px;
					color: #001253;
					display: flex;
					align-items: center;
					img{
						margin-right: 10px;
					}
				}
			}

		}
	}
	.container{
		max-width: 1360px;
	}
	.header__rightitem:not(:last-child) {
		margin-right: 30px;
	}
	.header__right{
		flex-direction: column;
		align-items: flex-end;
		.contact_header{
			margin-top: 20px;
			a{
				font-weight: 500;
				font-size: 14px;
				color: #c2915e;
				display: flex;
				align-items: center;
				img{
					margin-right: 8px;
				}
			}
		}
		.links_header{
			display: flex;
			span{
				font-weight: 600;
				font-size: 16px;
				color: #001253;

			}
		}
		.blockcart a, .user-info a{
			flex-direction: column;
			img{
				height: 60px;
				width: 60px;
				object-fit: contain;
				object-position: center;
				margin: 0 auto;
			}
			.cart-products-count {
				display: none;
			}
		}
	}

	.ets_mm_megamenu_content{
		border: none;
		background: transparent;
		position: relative;
		&:before{
			content:"";
			width: 100vw;
			height: 100%;
			background: #FBF7F2;
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
		}
		.ets_mm_megamenu_content_content{
			> .mm_menus_ul{
				padding: 10px 0;
				justify-content: center;
				display: flex;
				.mm_menus_li{
					border: none;
					&:hover{
						a{
							background: transparent;
						}
					}
					.mm_menu_content_title{
						font-weight: 500;
						font-size: 16px;
						color: #001253;
						padding: 0;
						text-transform: initial;
						margin: 0 10px;
						>img{
							max-height: 24px;
						}
					}
					&.brands{
						.mm_menu_content_title{
							font-weight: 600;
							font-size: 16px;
							letter-spacing: 0.03em;
							color: #fff;
							border-radius: 13px;
							background: #c2915e;
							box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
							padding: 5px 12px;
							line-height: 16px;

						}

					}
					&.ages{
						.mm_menu_content_title{
							font-weight: 600;
							font-size: 16px;
							letter-spacing: 0.03em;
							color: #fff;
							border-radius: 13px;
							background: #001253;
							box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
							padding: 5px 12px;
							line-height: 16px;
						}

					}
				}
			}
		}
	}
}

#blockcart-modal{
	.product-image{
		border-radius: 10px;
		border: 1px solid #f1d5a2;
		padding: 1px;
	}
	.col-delete{
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
	}
	.product-title{
		font-family: $lato;
		font-weight: 500;
		font-size: 16px;
		line-height: 16px;
		color: #001253;
	}
	.product-qty{
		font-family: $lato;
		font-weight: bold;
		font-size: 14px;
		line-height: 16px;
		text-align: left;
		color: #c2915e;
		margin-top: 10px;
		.qty{
			font-weight: normal;
		}
	}
	.price{
		font-family: $lato;
		font-weight: bold;
		font-size: 18px;
		text-align: center;
		color: #001253;
	}
	.remove-from-cart{
		color:  #001253;
	}
	.border-bottom{
		border-bottom: 1px dashed #f1d5a2 !important;
		border-top: none !important;
	}

	.modal-header{
		p.modal-title{
			font-family: $lato;
			font-weight: 500;
			font-size: 16px;
			//line-height: 16px;
			color: #000;
		}
		.close{
			opacity: 1;
			span{
				display: flex;
				align-items: center;
			}
		}

	}

	.modal-footer{
		border-top: none;
		position: relative;
		&:before{
			content: "";
			width: calc(100% - 30px);
			height: 4px;
			background-image: url(../img/border-modal-cart.svg);
			position: absolute;
			top: -2px;
			background-size: cover;
			left: 15px;
		}

		.btn-outline-secondary{
			font-family: $lato;
			font-weight: bold;
			font-size: 18px;
			letter-spacing: 0.05em;
			line-height: 24px;
			text-align: center;
			color: #c2915e;
			padding: 11px 15px;
			border: 1px #c2915e solid;
			border-radius: 30px;
			&:hover{
				color: #fff;
				background: #c2915e;
			}
		}

		.btn-primary{
			font-family: $lato;
			font-weight: 900;
			font-size: 16px;
			letter-spacing: 0.05em;
			line-height: 23px;
			color: #fff;
			text-transform: uppercase;
			padding: 11px 15px;
			border-radius: 30px;
			box-shadow: none;
			border: 1px solid #c2915e;
			background: #c2915e;
			&:hover{
				color: #fff;
				background:#001253 ;
				border-color: #001253;
			}
		}
		p.font-weight-bold{
			font-family: $lato;
			font-weight: bold;
			font-size: 16px;
			line-height: 16px;
			color: #001253;
		}
	}
}

#wrapper{
	max-width: 1360px;
}



#np_star_2952957_000000 {
	animation-name: blink;
	animation-duration: 4s;
	animation-iteration-count: infinite;
}


#np_star_2016468_000000-2{
	animation-name: blink;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}

#np_star_2016468_000000-3, #Tracé_819{
	animation-name: blink;
	animation-duration: 3s;
	animation-iteration-count: infinite;
}

#Tracé_821{
	animation-name: blink;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}

#np_star_2016468_000000, #Tracé_820{
	animation-name: blink;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}

#Tracé_818{
	animation-name: blink;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}

@keyframes blink {
	0% {
		opacity: 0;

	}
	25%{
		opacity: .5;

	}
	50% {
		opacity: 1;

	}
	75% {
		opacity: 0.5;

	}
	100%{
		opacity: 0;
	}
}

.jolisearch-body{
	max-height: 80vh;
	overflow-y: scroll !important;
	.jolisearch-content{
		> div{
          display: flex;
          flex-wrap: wrap;
		}
	}
}
.left-header-mobile{
	display: none;
}

@media (max-width: 1280px){
	#header .ets_mm_megamenu_content .ets_mm_megamenu_content_content>.mm_menus_ul .mm_menus_li .mm_menu_content_title>img{
		display: none;
	}
}

@media (max-width: 1090px){
	#header .header-top{
		padding: 10px 15px 25px;
	}
	.header__search{
		padding-right: 50px;
	}
	svg.logo{
		max-width: 100%;
	}
	#header .header__right .contact_header a{
		font-size: 13px;
	}
}

@media (max-width: 1115px){
	#header .header__right .links_header span{
		font-size: 14px;
	}
	#header .header__rightitem:not(:last-child){
      margin-right: 20px;
	}
}

@media (max-width: 991px){
	#header .header-top .header_left .content .address_header:before{
		display: none;
	}
	#header .header-top .header_left .content .horraires_header{
		margin-right: 0;
	}
	#header .header-top .header_left .content{
		align-items: baseline;
	}
}

@media (max-width: 767px){
	.header__search {
		width: 100%;
		padding: 0;
		flex: auto;
		order: 10;
		margin-top: 10px;
	}
	#header .header__search .logo{
		display: none;
	}
	.header-top__col{
		.contact_header{
			display: none;
		}
	}
	.header_left{
		display: none;
	}
	.left-header-mobile{
		display: flex;
		align-items: center;
		height: 100%;
		.ybc-menu-toggle{
			display: inline-block;
			width: auto;
			padding-left: 0;
		}
	}
	.container-menu .ybc-menu-toggle{
		height: 0;
		opacity: 0;
		padding: 0;
	}
	#header .header-top{
		align-items: center !important;
	}
	#header .header__right .blockcart a img, #header .header__right .user-info a img{
		height: 45px;
	}
	#header .header__rightitem:not(:last-child) {
		margin-right: 5px;
	}
	#header{
		min-height: 185px;
	}
	#header-sticky{
		&.sticky{
			backdrop-filter: none;
			.header-top__col_sticky{
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				z-index: 100;
				background: #fff;
				box-shadow: 0 0 20px rgba(0,0,0,.15);
			}

			.header__search{
				display: none;
			}
			.header-top{
				padding: 10px 15px 10px;
			}
		}
	}
	#header-sticky.sticky .container-menu{
		z-index: 1000;
		height: 0;
	}

}

#header .changestatus .ets_mm_megamenu_content .ets_mm_megamenu_content_content>.mm_menus_ul{
	display: block;
	padding-top: 32px;
}
#header .changestatus{
	.pull-right{
		.mm_menus_back_icon.first{

			transform: rotate(135deg);
			margin-right: -2px;

		}
	}
	.close_menu{
		background-color: #001253!important;
	}
}


#header .sticky .changestatus .ets_mm_megamenu_content .ets_mm_megamenu_content_content>.mm_menus_ul{
	padding-top: 0px;
}

.mm_menus_ul{
	.mm_menus_li{
		position: relative;
		&.sub_right{
			.mm_columns_ul{
				right: 0;
				left: auto;
				display: flex;
				justify-content: end;
			}
		}
	}
}

.mm_columns_ul{
	padding-top: 0 !important;
	border: none !important;
	background-color: transparent!important;
	width: 100vw!important;
	.mm_columns_li.column_size_12{
		display: inline-block;
		width: auto;

		.ek_menu{
			background: #fff;
			padding: 15px;
			filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
			width: calc(100% + 80px);
			.row .ek_sub_menu{
				&:first-child{
					position: relative;
					&:before{
						content:"";
						width: 3px;
						height: 100%;
						background-image: url("../img/border_sub_menu.svg");
						position: absolute;
						right: -2px;
						top: 0;
						background-size: contain;
					}

				}
			}
		}
	}
}

.ek_sub_menu{
	ul{
		li{
			//white-space: nowrap;
			//width: fit-content;
			a{
				display: flex;
				align-items: center;
				padding: 10px;
				img{
					width: 24px;
					height: 100%;
					margin-right: 10px;
					max-height: 26px;
					object-fit: contain;
				}
			}
			&.all{
				position: absolute;
				bottom: 0;
				right: 20px;
				a{
					span{
						font-family: $lato;
						font-weight: 600;
						font-size: 14px;
						color: #c2915e;
						display: flex;
						align-items: center;

						svg{
							margin-left: 10px;
						}
					}

				}
			}
		}
	}
}


.mm_menu_content_title{
	.mm_arrow{
		display: none;
	}
}


.changestatus{
	.mm_menus_ul .mm_menus_li.sub_right .mm_columns_ul{
		display: none;
	}
	.mm_columns_ul .mm_columns_li.column_size_12 .ek_menu .row .ek_sub_menu:first-child:before{
		display: none;
	}
	.ek_sub_menu ul li.all{
		position: initial;
	}
	.mm_columns_ul .mm_columns_li.column_size_12 .ek_menu{
		.col-md-6{
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.mm_columns_ul .mm_columns_li.column_size_12 .ek_menu{
		max-width: 100%;
	}
}

#index{
	#header{
		margin-bottom: 0 !important;
	}
}