
#checkout {
  #header {
    min-height: auto;
  }
}

.step-number {
  font-weight: $font-weight-bold;
  color: $gray-400;
}
