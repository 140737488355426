body .ps17.ui-menu.ui-jolisearch {
	position: fixed;
	border: 0;
	background: none;

	@media screen and (max-width: 769px) {
		width: calc(100vw - 25px) !important;
		max-width: calc(100vw - 25px);
	}
	
	.no-results-found.ui-menu-item,
	.jolisearch-body {
		box-shadow: $box-shadow;
		transform: translateY(-2px);
		border: $border-width solid $border-color;
		background: #fff;

		@media screen and (max-width: 769px) {
			box-shadow: none;
			border-color: $input-border-color;
		}
	}
	
	
	.no-results-found.ui-menu-item {
		text-transform: uppercase;
		font-weight: 800;
		font-size: 14px;
		color: $black;

		.ui-state-focus {
			border: 0;
			background: none;
			margin: 0;
		}
		a.ui-state-active {
			font-weight: inherit;
		}
		.jolisearch-post {
			&:hover {
				background: none;
				border: 0;
			}
		}
	}
	
	.jolisearch-body {
		padding: 20px 15px;

		@media screen and (max-width: 769px) {
			padding: 10px 0;
		}
		
		h4 {
			color: $black;
			letter-spacing: 0.01em;
		}
		
		section[role="main"] {
			.more-results {
				a {
					display: inline-block;
					padding: 0.3rem 1.25rem 0.25rem;
					background: none;
					border: 0;
					color: $black;
					letter-spacing: 0.01em;
					text-transform: uppercase;
					transition: all ease 0.33s;
					&:focus,
					&:active,
					&:hover {
						background: $primary;
						border: 0;
						color: $white;
					}
				}
			}
			
			.col-lg-3-12 {
				@media screen and (min-width: 1200px) {
					width: calc(25% - 10px);
				}
				@media screen and (min-width: 992px) {
					width: calc(33% - 10px);
				}
			}
			.product {
				margin: 5px 0;
				overflow: hidden;
				transition: all ease 0.3s;
				clear: none;
				
				&:hover {
					background-color: $gray-100;
					border: 1px solid $gray-100;
				}
				
				img {
					margin: -10px -15px 0.5rem;
					max-width: calc(100% + 30px);
					width: calc(100% + 30px);
				}
				.jolisearch-product span {
					&.product-name {
						color: $black;
						text-align: center;
						font-size: 14px;
						line-height: 1.2;
						margin-bottom: 0.6rem;
					}
					&.product-price {
						font-size: 16px;
						font-weight: 800;
					}
				}
			}
			.items-count {
				font-size: 11px;
				display: inline-block;
				padding: 0.1rem .5rem;
				border-radius: 0;
				background-color: $primary;
				color: $white;
				font-weight: $font-weight-bold;
				vertical-align: 3px;
			}
		}
		
		aside[role="complementary"] {
			> div {
				margin-bottom: 1rem;
				padding-bottom: 0;
			}
			li {
				padding-top: 0;
				padding-bottom: 0;
				border: 0;
				
				&:hover {
					background: none;
					border: 0;
				}
				
				a {
					display: inline-block;
					padding: 3px 4px 2px 4px;

					&:hover {
						color: $primary;
					}
				}
			}
		}
		
	}
}
