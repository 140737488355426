#blockcart-modal{
  .product-quantity{
    .input-group{
      max-width: 130px;
    }
  }
}

#cart{

  .title-cart{
    position: relative;
    &:before{
      content:"";
      width: 100%;
      height: 4px;
      background-image: url("../img/border-title-cart.svg");
      position: absolute;
      top: 55%;
      transform: translateY(-50%);
    }
    h1{
      font-family: $cormorant;
      font-weight: bold;
      font-size: 35px;
      line-height: 16px;
      color: #001253;
      margin-top: 40px;
      margin-bottom: 40px;
      display: inline-block;
      position: relative;
      background: #fff;
      padding-right: 15px;
    }
  }
  .product-line-grid{
    .media{
      img{
        border-radius: 10px;
        border: 1px solid #f1d5a2;
        padding: 1px;
      }
    }

    .product-line__title{
      font-family: $lato;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #001253;
      margin-bottom: 5px;
    }
    .product-ref{
      font-family: $lato;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.07em;
      color: #c2915e;
      margin-bottom: 0;
    }
    .product-line-info{
      margin-top: 30px;
    }
    .bootstrap-touchspin{
      border: none;
      background: #FBF7F2;
      border-radius: 30px;
      padding: 8px 12px;

      .btn.js-touchspin{
        width: 24px;
        height: 24px;
        background: #C2915E;
        line-height: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50%;
        transition-duration: 0.2s;
        &:hover{
          transition-duration: 0.2s;
          transform: scale(1.08);
        }
      }
      .js-cart-line-product-quantity{
        height: 24px;
        border: none;
        background: transparent;
        font-family: $lato;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 23px;
        text-align: center;
        color: #c2915e;
      }
    }
    .product-price{
      font-family: $lato;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #001253;
    }

    .stock-product{
      .in_stock{
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        color: #c2915e;
        margin-bottom: 0;
        background: #f8f2ea;
        border-radius: 15px;
        padding: 0 10px;
        display: inline-block;
      }
      .out_of_stock{
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        color: #fff;
        margin-bottom: 0;
        background: #001253;
        border-radius: 15px;
        padding: 0 10px;
        display: inline-block;

      }
    }
  }
  .cart-item:not(:last-child){
    border-bottom: 1px dashed #f1d5a2!important;
  }

  .cart-grid-right{
    .cart-summary{
      background: #FBF7F2;
      border-radius: 30px;
      padding: 15px 5px 25px;
      .cart-summary-line.cart-total{
        font-family: $lato;
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;
        color: #001253;
      }
      .btn-primary{
        font-family: $lato;
        font-weight: 900;
        font-size: 16px;
        letter-spacing: .05em;
        line-height: 23px;
        color: #fff;
        text-transform: uppercase;
        padding: 11px 15px;
        border-radius: 30px;
        box-shadow: none;
        border: 1px solid #c2915e;
        background: #c2915e;
      }
    }
  }
}