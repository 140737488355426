@media (min-width: 992px){
  body.layout-full-width .product-miniature {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.product-miniature{
  .card-body{
    padding: 20px 0;
  }
  .card-product{
    background: transparent;
    .card-img-top{
      border-radius: 10px;
      border: 1px solid #f1d5a2;
    }
    .product-title{
      a{
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #001253;
        text-transform: capitalize;
      }
    }
    .manufacturer{
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.07em;
      text-align: center;
      color: #001253;
      text-transform: uppercase;
    }
    .add-to-cart{
      font-size: 0;
      width: 40px;
      height: 40px;
      border-radius: 5px;
      background: #C2915E;
      border-color: #C2915E;
      box-shadow: none;
      background-image: url("../img/cta4.svg");
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: center;
    }
    .container_add_to_cart{
        display: flex;
      justify-content: space-between;
      >div{
        width: 50%;
        &.product-list-actions{
          display: flex;
          justify-content: center;
        }
      }
      .price{
        font-weight: bold;
        font-size: 18px;
        text-align: left;
        color: #001253;
        line-height: 18px;
      }

      .col-price{
        display: flex;
        justify-content: center;
        .price_stock{
          display: inline-block;
          .product-price-and-shipping{
            line-height: 22px;
          }
        }
        .stock-product{
          margin-top: 2px;
          .in_stock{
            font-weight: 600;
            font-size: 12px;
            text-align: center;
            color: #c2915e;
            margin-bottom: 0;
            background: #F8F2EA;
            border-radius: 15px;
            padding: 0 5px;
            margin-left: -5px;
            line-height: 1.4;
          }
          .out_of_stock{
            font-weight: 600;
            font-size: 12px;
            text-align: center;
            color: #fff;
            margin-bottom: 0;
            background: #001253;
            border-radius: 15px;
            padding: 0 10px;
            margin-left: -5px;
            white-space: nowrap;
            line-height: 1.4;
          }
        }

      }
    }
  }
}

.PM_ASBlockOutputVertical,.PM_ASBlockOutput,.PM_ASBlockOutputHorizontal{
  .card-header{
    display: none;
  }
  .PM_ASCritRange{
    border: none;
    background: #F8F2EA;
    border-radius: 20px;
    margin-top: 25px;
    .ui-slider-range{
      background: #DCC1A3;
    }
    .ui-slider-handle{
      background: #C2915E;
      border: none;
      border-radius: 50%;
      top: -3px;
    }
  }

  .PM_ASCriterionsGroupTitle{
    background: #FBF7F2;
    padding: 7px 12px!important;
    border: none !important;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    &:before{
      content:"";
      width: 20px;
      height: 20px;
      background-image: url("../img/np_star_filter.svg");
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      transition-duration: 0.2s;
    }
    &.active{
      &:before{
        transform: translateY(-50%) rotate(45deg);
        transition-duration: 0.2s;
      }
    }
    img{
      margin-right: 8px;
    }
    .PM_ASCriterionsGroupName{
      font-family: $lato;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.03em;
      color: #001253;
    }
  }
  .PM_ASCriterionGroupCheckbox{
    padding-left: 5px;
    input{
      display: none;
    }
    .PM_ASLabelCheckbox{
      position: relative;
      padding-left: 25px;
      &:before{
        content: "";
        width: 11px;
        height: 11px;
        position: absolute;
        left: 5px;
        top: 8px;
        border: 2px solid #001253;
        border-radius: 50%;

    }
      &.PM_ASLabelCheckboxSelected{
        &:before{
          background: #f0d4a2;
        }
      }
      .PM_ASLabelLink{
        font-family: $lato;
        font-weight: normal;
        font-size: 14px;
        text-align: left;
        color: #001253;
      }
      .PM_ASCriterionNbProduct{
        display: none;
      }
    }
  }
}



.block-category{
  .card-body{
    background: #FBF7F2;
    padding: 0;
    margin: 40px 0;
  }
  .category-cover{
    position: relative;
    &:before{
      content:"";
      height: 90%;
      width: 100px;
      background-image: url("../img/after-img-cat.svg");
      position: absolute;
      right: 0;
      top: 5%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
    }
    img{
      width: 100%;
      height: auto;
      -o-object-fit: cover;
      object-fit: cover;
      max-height: 380px;
      border-radius: 0 300px 300px 0;
      margin-left: -15px;

    }
  }
  .col-cat-desc{
    display: flex;
    align-items: center;
    >div{
      max-width: 475px;
      margin: 0 auto;
      .breadcrumb{
        margin-bottom: 0;
        padding: 0;
        font-family: $lato;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.05em;
        line-height: 23px;
        color: #001253;
        .breadcrumb-item.active{
          span{
            color: #001253;
            font-weight: bold;
          }
        }
      }
      h1{
        font-family: $cormorant;
        font-weight: normal;
        font-size: 45px;
        letter-spacing: 0.07em;
        color: #001253;
        position: relative;
        margin-bottom: 30px;
        display: inline-block;
        &:before{
          content: "";
          width: 98%;
          height: 5px;
          background-image: url(../img/border-title.svg);
          position: absolute;
          bottom: -2px;
          background-size: cover;
        }
      }
      #category-description{
        font-family: $lato;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 23px;
        text-align: left;
        color: #000;
      }
    }
  }
}

#select-sort-order{
  position: absolute;
  right: 0;
  opacity: 0;
}

#js-product-list-top{
  .product__quantity{
    font-family: $lato;
    font-weight: normal;
    font-style: italic;
    font-size: 14px;
    line-height: 23px;
    color: #001253;
  }
}

.pagination{
  margin: 30px 0 !important;

  .page-item{
    a{
      width: 25px;
      height: 25px;
      border-radius: 50%;
      padding: 0;
      font-family: $lato;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      text-align: center;
      color: #001253;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      border: none;
    }
    &.next{
      position: absolute;
      right: 0;
      a{
        width: auto;
        height: auto;
        &:hover{
          background-color: transparent;
        }
      }
      .arrow-ek{
        width: 33px;
        height: 33px;
        background: #001253;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin-left: 15px;
      }

    }
    &.prev{
      position: absolute;
      left: 0;
      a{
        width: auto;
        height: auto;
        &:hover{
          background-color: transparent;
        }
      }
      .arrow-ek{
        width: 33px;
        height: 33px;
        background: #001253;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin-right: 15px;
        transform: rotate(180deg);
      }
    }
    &.disabled{
      span{
        border: none;
        width: 25px;
        height: 25px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #001354;
      }
    }
  }
}

.breadcrumb-item+.breadcrumb-item:before{
  background-image: url("../img/pseudo_nav.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 8px;
  font-size: 0;
}

@media (min-width: 992px){
  #category .product-miniature {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 8px;
  }
}

@media (max-width: 991px){
  #header.l-header{
    margin-bottom: 25px !important;
  }
}

@media (max-width: 767px){
  .sort-by-row{
    justify-content: center !important;
    margin-bottom: 15px;
  }
}

.block-category .category-cover{
  height: 100%;
  display: flex;
  align-items: center;
}


#js-product-list{
  padding-bottom: 100px;
}