.first-article{
  .block_cont{
    background: #001253;
    padding: 50px 80px;
    border-bottom-right-radius: 500px;
    border-top-right-radius: 500px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    position: relative;
    >svg{
      position: absolute;
      left: 15px;
      top: 10px;
      z-index: 0;
    }
    .block_top{
      width: 50%;
      padding-right: 50px;
      position: relative;
      z-index: 2;

      img{
        width: 100%;
        max-height: 320px;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .block_bas{
      width: 50%;
      h3{
        font-family: $cormorant;
        font-weight: normal;
        font-size: 28px;
        letter-spacing: 0.04em;
        line-height: 30px;
        max-width: 400px;
        color: #fff;
      }
      .blog_desc{
        font-family: $lato;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 23px;
        color: #fff;
      }
    }
    .prestablog_more{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        svg{
          margin-left: 10px;
        }
    }
  }
}

#module-prestablog-blog{
  h1{
    font-family: $cormorant;
    font-weight: 400;
    font-size: 45px;
    letter-spacing: .07em;
    text-align: center;
    color: #001253;
    >span{
      display: block;
      span{
        color: #c2915e;
        position: relative;
        margin-bottom: 30px;
        display: inline-block;
        &:after{
          content: "";
          width: 98%;
          height: 4px;
          background-image: url(../img/border_title_blue.svg);
          position: absolute;
          bottom: -2px;
          background-size: cover;
          left: 0;
        }
      }
    }

  }
  .prestablog_pagination{
    padding-bottom: 100px;
    padding-top: 70px;
    margin-top: 30px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before{
      content:"";
      width: 100vw;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      background: #FBF7F2;
      z-index: -1;
    }
    a{
      width: 25px;
      height: 25px;
      border-radius: 50%;
      padding: 0;
      font-family: $lato;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      text-align: center;
      color: #C2915E;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      border: none;
      background: transparent;
    }
    .current{
      color: #fff;
      background-color: #C2915E;
      border-color: #C2915E;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      padding: 0;
      font-family: lato,sans-serif;
      font-size: 15px;
      line-height: 23px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      font-weight: bold;
    }
    .next {
      width: auto;
      height: auto;
      position: absolute;
      right: 0;

      .arrow-ek {
        width: 33px;
        height: 33px;
        background: #C2915E;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin-left: 15px;
      }
    }
      .prev{
        width: auto;
        height: auto;
        position: absolute;
        left: 0;
        border-radius: 50%;
        font-weight: 400;
        color: #c2915e;
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        .arrow-ek{
          width: 33px;
          height: 33px;
          background: #c2915e;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          margin-right: 15px;
          transform: rotate(180deg);
        }
      }
  }
}

.ek_prestablog{
  .blog-grid:not(.first-article){
    margin-bottom: 30px;
    .block_top{
      a >img{
        max-width: 100%;
        border-radius: 8px;
        max-height: 260px;
        width: 100%;
        object-fit: cover;
      }
    }
    h3 a{
      font-family: $cormorant;
      font-weight: 600;
      font-size: 28px;
      letter-spacing: 0.04em;
      line-height: 30px;
      color: #001253;
    }
    .block_bas{
      padding-top: 15px;
      .blog_desc{
        font-family: $lato;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 23px;
        color: #000;
      }
    }
    .prestablog_more{
      text-align: right;
      a{
        font-family: $lato;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.08em;
        line-height: 23px;
        color: #c2915e;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        svg{
          margin-left: 10px;
        }
      }
    }
  }
}

#prestablogfront, .prestablogExtra{
  border: none !important;
  max-width: 1100px;
  margin: 0 auto;
  .img-blog{
    img{
      width: 100%;
      max-height: 700px;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  #prestablog_article{
    font-family: $cormorant;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    color: #001253;
    position: relative;
    margin-bottom: 40px;
    overflow: visible;
    &:before {
      content: "";
      width: 245px;
      height: 4px;
      background-image: url(../img/after-marque.svg);
      position: absolute;
      bottom: -2px;
      background-size: cover;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .content-article{
    max-width: 880px;
    margin: 0 auto;
  }

}

.infos-blog{
  position: relative;
  &:before{
    content: "";
    width: 100vw;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background: #fbf7f2;
    z-index: -1;
  }
  .share{
    padding-bottom: 100px;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: end;
    max-width: 1100px;
    margin: 0 auto;
  }

  .label{
    white-space: nowrap;
    margin-right: 10px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-family: $lato;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.1em;
    line-height: 23px;
    text-align: left;
    color: #001253;
    text-transform: uppercase;
    svg{
      margin-right: 8px;
      margin-top: 2px;

    }
  }
  .soc-buttons{
    height: auto;
    display: flex;
    align-items: center;
    width: fit-content;
    li{
      width: auto !important;
      margin-right: 5px;
      a{
        padding: 0 !important;
        background: transparent !important;
        font-size: 0;
        width: auto;
        svg{
          path{
            fill: #001253 !important;
          }
        }
      }
    }
  }
}