//color
$bg-dark: #dededd;
$tertiary: #d04d00;

//option
$enable-fluid-layout: false;

/* HEADER */
$header-bg: $white;
$header-box-shadow: 0 0 20px rgba(#000,0.15);
$header-nav-bg-color: null;
$header-nav-color: $body-color;
$header-nav-padding-y: null;
$header-nav-border-color: $border-color;
$header-top-border-color: $header-nav-border-color;

/* MAIN */
$spacer-y: $spacer*2;
$wrapper-bg: #fff;

/* FOOTER */
$footer-bg: #2f2f2f;
$footer-container-margin-top: $spacer;
