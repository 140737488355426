.ekypia_slider_home img {
  height: 370px !important;
  width: 100%;
  object-fit: cover;
  position: relative;


}
.ekypia_slider-container{
  margin-bottom: 0 !important;
  z-index: 2;
}
#index .ekypia_slider_home {
  width: 2000px;
  max-width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  .slick-list{
    height: 370px !important;
  }
  .slide{
    &:before{
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(to right ,rgba(0,0,0,.85),rgba(0,0,0,.65) 31.53%,transparent);
      opacity: .3;
      z-index: 1;
    }
  }
  .caption.position_0{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    .content{
      padding: 50px 60px;
    }
  }
  .caption{
    .content{
      .titre{
        font-family: $cormorant;
        font-weight: 600;
        font-size: 35px;
        letter-spacing: 0.07em;
        line-height: 35px;
        color: #fff;
        max-width: 280px;
        display: block;
        margin-bottom: 20px;
      }
      .btn{
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.05em;
        line-height: 23px;
        color: #001253;
        text-transform: uppercase;
        background: #FBF7F2;
        border-color: #FBF7F2;
        border-radius: 40px;
        padding: 7px 20px;
        transition-duration: 0.2s;
        &:before , &:after{
          content: "";
          width: 0px;
          height: 0px;
          display: inline-block;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("../img/pseudo_nav.svg");
          transition-duration: 0.2s;
        }
        &:hover{
          transform: scale(1.05);
          transition-duration: 0.2s;
          &:before , &:after{
            content: "";
            width: 10px;
            height: 10px;
            display: inline-block;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("../img/pseudo_nav.svg");
            transition-duration: 0.2s;
          }
          &:before{
            margin-right: 10px;
          }
          &:after{
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.featured-products{
  .products-section-title{
    font-family: $cormorant;
    font-weight: normal;
    font-size: 45px;
    letter-spacing: 0.07em;
    color: #c2915e;
    line-height: 45px;
    max-width: 470px;
    margin: 0 auto;
    margin-bottom: 60px;
    text-transform: none;
    text-align: left;
    span{
      font-weight: normal;
      font-size: 45px;
      letter-spacing: 0.07em;
      color: #001253;
      display: block;
      line-height: 45px;
      text-align: right;
      span{
        display: inline-block;
        position: relative;
        &:before{
          content:"";
          width:98%;
          height: 5px;
          background-image: url("../img/border-title.svg");
          position: absolute;
          bottom: -2px;
          background-size: cover;
        }
      }
    }
  }
  &.new-products{
    position: relative;
    padding-top: 20px;
    .paralax-left{
      position: absolute;
      left: -30px;
      top: -100px;
    }
    .paralax-right{
      position: absolute;
      right: -200px;
      top: -40px;
    }
  }
}

.fake-rea{
  .elementor-widget-heading{
    margin-bottom: 0 !important;
  }
  h2.elementor-heading-title{
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.15em;
    text-align: center;
    color: #c2915e;
    text-transform: uppercase;
  }
  span.elementor-heading-title{
    font-family: $cormorant;
    font-weight: 400;
    font-size: 22px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #001253;

  }
  .elementor-image{
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      max-height: 100%;
    }
  }

  .title-rea .elementor-heading-title{
    font-family: $cormorant;
    font-weight: normal;
    font-size: 45px;
    letter-spacing: 0.07em;
    color: #001253;
    line-height: 45px;
    max-width: 470px;
    margin: 0 auto;
    margin-bottom: 60px;
    text-transform: none;
    text-align: left;
    span{
      font-weight: normal;
      font-size: 45px;
      letter-spacing: 0.07em;
      color: #c2915e;
      display: block;
      line-height: 45px;
      text-align: right;
      span{
        display: inline-block;
        position: relative;
        &:before{
          content:"";
          width:98%;
          height: 5px;
          background-image: url("../img/border_title_blue.svg");
          position: absolute;
          bottom: -8px;
          background-size: cover;
        }
      }
    }
  }
  &.bottom{
    &:before{
        content: "";
        width: 100%;
        height: 90px;
        background-image: url("../img/etoile-bleu.svg");
        position: absolute;
        top: 0;
      max-width: 1080px;
        left: 50%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        transform: translateX(-50%);
      }
    &:after{
      content: "";
      width: 100%;
      height: 90px;
      background-image: url("../img/etoile-doree.svg");
      position: absolute;
      bottom: 0;
      max-width: 1080px;
      left: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
      transform: translateX(-50%);
    }

  }
}


#background-round{
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 40px;
  &:before{
    content:"";
    width: 100%;
    padding-top: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    background: #FBF7F2;
    border-radius: 50%;
    transform: translate(-50% ,-50%);
  }
  .elementor-widget-heading{
    margin-bottom: 0 !important;
  }
  h2.elementor-heading-title{
    font-family: $cormorant;
    font-weight: normal;
    font-size: 45px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #c2915e;
  }
  span.elementor-heading-title{
  font-family: $cormorant;
  font-weight: normal;
  font-size: 45px;
  letter-spacing: 0.07em;
  text-align: center;
  color: #001253;
    position: relative;
    margin-bottom: 30px;
    display: inline-block;
    &:after{
      content: "";
      width: 98%;
      height: 4px;
      background-image: url(../img/border-title.svg);
      position: absolute;
      bottom: -2px;
      background-size: cover;
      left: 0;
    }
  }
  .elementor-text-editor{
    font-family: $lato;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 23px;
    text-align: center;
    color: #001253;
    margin: 0 auto;
    max-width: 450px;
  }
  .elementor-button-link{
    background-color: #C2915E !important;
    border-radius: 30px;
    span{
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.05em;
      line-height: 14px;
      text-align: center;
      color: #fbf7f2;
      text-transform: uppercase;
    }
  }
}

.elementor-cta--skin-cover .elementor-cta-content{
  padding: 20px;
  text-align: left;
  flex-direction: column;
  padding-top: 65px;
  min-height: 250px !important;
  &:before{
    content: "";
    height: 180px;
    width: 100%;
    background-image: url("../img/triangle.svg");
    position: absolute;
    left: 0;
    background-size: contain;
    top: 0;
    background-repeat: no-repeat;
  }
  .elementor-cta-button-wrapper{
    position: absolute;
    bottom: 20px;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    text-align: right;
    padding-right: 20px;
    .elementor-button{
      font-weight: 700;
      font-size: 14px;
      letter-spacing: .05em;
      line-height: 14px;
      color: #001253;
      text-transform: uppercase;
      background: #fbf7f2;
      border-color: #fbf7f2;
      border-radius: 40px;
      padding: 7px 20px;
    }
  }
  .elementor-cta-title{
    line-height: 25px;
    margin-bottom: 0 !important;
    text-align: left;
    font-family: $cormorant;
    font-weight: 500;
    font-size: 35px;
    letter-spacing: 0.02em;
    color: #f1d5a2;

  }
  .elementor-cta-description{
    line-height: 25px;
    margin-bottom: 0 !important;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .15em;
    color: #fff;
    text-transform: uppercase;

  }
}

.col-histoire{
  padding: 30px;
  background: #fff;
  padding-top: 80px;
  border-top-left-radius: 280px;
  border-top-right-radius: 280px;
  position: relative;
  &:before{
    content:"";
    width: 150px;
    height: 100px;
    position: absolute;
    background-image: url("../img/before-histoire.svg");
    left: -25px;
    top: 0;
    z-index: 0;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .elementor-image{
    img{
      max-width: 400px;
    }
  }
  .elementor-text-editor{
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 23px;
    text-align: center;
    color: #000;
  }
  .elementor-button-link{
    background-color: #c2915e !important;
    border-radius: 30px;
    span{
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.05em;
      text-align: center;
      color: #fff;
      line-height: 14px;
      text-transform: uppercase;
    }
  }
  .elementor-widget-heading{
    margin-bottom: 0 !important;
  }
  h2.elementor-heading-title{
    font-family: $cormorant;
    font-weight: normal;
    font-size: 45px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #001253;
  }
  span.elementor-heading-title{
    font-family: $cormorant;
    font-weight: normal;
    font-size: 45px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #C2915E;
    position: relative;
    margin-bottom: 30px;
    display: inline-block;
    &:after{
      content: "";
      width: 98%;
      height: 4px;
      background-image: url(../img/border_title_blue.svg);
      position: absolute;
      bottom: -2px;
      background-size: cover;
      left: 0;
    }
  }
}


#last-post{
  max-width: 400px;
  margin-right: 0;
  margin-left: auto;
  position: relative;
  &:before{
    content: "";
    width: 110px;
    height: 50px;
    position: absolute;
    background-image: url(../img/before-first-actu.svg);
    right: -30px;
    top: 0;
    z-index: 0;
    background-size: contain;
    background-repeat: no-repeat;
  }

  h2.title{
    font-family: $cormorant;
    font-weight: 400;
    font-size: 45px;
    letter-spacing: .07em;
    text-align: right;
    color: #fff;
    text-transform: initial !important;
    span{
      color: #c2915e;
      position: relative;
      margin-bottom: 30px;
      display: inline-block;
      &:before{
        content: "";
        width: 98%;
        height: 4px;
        background-image: url(../img/border-title-white.svg);
        position: absolute;
        bottom: -2px;
        background-size: cover;
        left: 0;
      }
    }
  }

  .blog-grid{
    .block_top{
      img{
        width: 100%;
        max-height: 270px;
        object-fit: cover;
        border-bottom-left-radius: 150px;
        border-top-left-radius: 150px;
      }
    }
    .block_bas{
      margin-top: 20px;
      h3 a , h3{
        font-family: $cormorant;
        font-weight: normal;
        font-size: 28px;
        letter-spacing: 0.04em;
        line-height: 30px;
        text-align: right;
        color: #fff;
      }

      .blog_desc{
        font-family: $lato;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 23px;
        text-align: right;
        color: #fff;
        margin: 25px 0;
      }
    }
    .prestablog_more{
      text-align: right;
      a{
        font-family: $lato;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.08em;
        line-height: 23px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        svg{
          margin-left: 10px;
        }
      }

    }
  }
}

body, html{
  overflow-x:hidden ;
}
#placeReviews{
  position:relative;
}
.google-reviews-section-card{
  position: absolute !important;
  height: auto;
  width: 100%;
  left: 33.333%;
  top: 50%;
  transform: translateY(-50%);
}
.bgReviews{
  height: auto !important;
  padding: 15px 10px;
  .placereviews_flexrow {
    .nameReviews{
      width: 100%;
      margin: 0;
    }

  }
  .paddingText{
    padding: 0;
  }
}

.content-review{
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  padding: 15px 20px 25px;
  height: 100%;

  .csoft-author-name{
    font-family: $lato;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 23px;
    color: #001253;
  }

  .wp-star{
    path{
      fill : #C2915E;
    }
  }
  .csoft-time-description{
    font-family: $lato;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #c2915e;
  }
  .paddingText{
    font-family: $lato;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 23px;
    color: #001253;
  }
}

#placeReviews{
  margin-top: -32px;
  padding: 60px 0 !important;
  .google-reviews-section-title{
    font-family: $cormorant;
    font-weight: normal;
    font-size: 30px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #c2915e;
    text-transform: initial;
    margin-top: 0;
  }
  .google-reviews-section-info{
    margin-top: 0;
    margin-bottom: 0;
    > p{
      font-family: $cormorant;
      font-weight: bold;
      font-size: 30px;
      text-align: center;
      color: #001253;
      margin-bottom: 0;
    }
  }

  .reviews-count{
    font-family: $lato;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #c2915e;
  }

  .carre{
    width: 270px;
    height: 270px;
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: solid 8px #F8F0E8;
    &:before{
      content: "";
      width: 8px;
      height: 65px;
      background: #f8f0e8;
      position: absolute;
      left: 50%;
      top: -68px;
      transform: translateX(-50%);

    }
  }

  .csoft-ratecolor{
    font-family: $lato;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #001253;

  }

  .slick-prev{
    position: absolute;
    left: -11%;
    font-size: 0;
    width: 33px;
    height: 33px;
    background: #C2915E;
    border: none;
    border-radius: 5px;
    bottom: 0;
    background-image: url("../img/np_arrow_5732592_000000.svg");
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
    transform: translateX(-50%) rotate(180deg);
  }
  .slick-next{
    position: absolute;
    left: calc(-11% + 45px);
    font-size: 0;
    width: 33px;
    height: 33px;
    background: #C2915E;
    border: none;
    border-radius: 5px;
    bottom: 0;
    background-image: url("../img/np_arrow_5732592_000000.svg");
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .google-reviews-section-info{
    .wp-stars{
      .wp-star{
        svg{
          width: 20px;
          height: 20px;
          path{
            fill:#C2915E;
          }
        }
      }
    }
  }

}


.position-initial{
  position: initial;
}

@media (max-width: 1150px){
  .featured-products.new-products .paralax-right,
  .featured-products.new-products .paralax-left{
    display: none;
  }
  .col-histoire .elementor-image img{
    max-width: 100%;
    width: 400px;
  }
}


@media (max-width: 991px){
  .google-reviews-section-card{
    position: initial !important;
    left: 0;
    transform: none;
    margin-top: 30px;
  }
  .featured-products .slick-next, .featured-products .slick-prev{
    bottom: -20px !important;
  }
}

@media (max-width: 767px){
  .fake-rea.bottom:after, .fake-rea.bottom:before{
    display: none;
  }

  .col-histoire{
    padding: 80px 15px 30px;
    margin-bottom: 40px;
  }

  #index .ekypia_slider_home .caption .content .titre{
    margin: 0 auto 20px;
  }
  #index .ekypia_slider_home .caption.position_0 .content{
    padding: 50px 15px;
    text-align: center;
  }
  #index .ekypia_slider_home .caption.position_0{
    width: 100%;
  }
}

#contact .js-customer-form{
  max-width: 700px;
}

#login-form, .forgotten-password, #customer-form , #contact .js-customer-form {
  padding: 15px;
  background: #fbf7f2;
  border-radius: 8px;
  color: #001253;

  .btn{
    &[data-action="show-password"]{
      background: #c2915e;
      border-color: #c2915e;
      color: #fff;
      box-shadow: none;
    }
  }
  .form-footer{
    text-align: center;
  }
  #submit-login{
    background: #001253;
    box-shadow: 0 0 6px rgba(0,0,0,.16);
    border-radius: 40px;
    padding: 5px 30px;
  }
  margin-bottom: 60px;
}

#authentication , #password, #my-account, #identity, #addresses, #module-blockwishlist-lists , #view{
  h1{
    color: #001253;
    text-align: center;
  }
  #footer{
    margin-top: 60px;
  }
}


.account-links{
  .link-item{
    border-radius: 10px;
    border: 1px solid #C2915E;
    background: #C2915E;
    color: #fff;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    height: 100%;
    min-height: 180px;
    align-items: center;
    justify-content: center;
  }
}

.wishlist-modal+.modal-backdrop.in{
  display: none;
}

.wishlist-list{
  li{
    background: #fbf7f2;
    border: none!important;
    border-radius: 5px;
    margin-bottom: 17px;
  }
}

.wishlist-modal:not(.show){
  display: none !important;
}

.wishlist-products-container-header{
  flex-direction: column;
  .sort-by-row{
    display: none !important;
  }
}

.wishlist-product{
  .wishlist-product-image{
    border-radius: 10px;
    border: 1px solid #f1d5a2;
  }
  .wishlist-product-title{
    font-weight: 700;
    font-size: 14px;
    letter-spacing: .07em;
    text-align: center;
    color: #001253;
    text-transform: uppercase;
  }
  .wishlist-product-addtocart{
    height: 40px;
    border-radius: 5px;
    background: #c2915e;
    border-color: #c2915e;
    box-shadow: none;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 50%;
    font-weight: bold;
    i{
      display: none;
    }
  }
  .wishlist-product-price{
    font-weight: 700;
    font-size: 18px;
    text-align: left;
    color: #001253;
    line-height: 18px;
  }
}


#cms{
  h1{
    font-family: $cormorant;
    font-weight: normal;
    font-size: 45px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #001253;
    span{
      display: block;
      span{
        font-family: $cormorant;
        font-weight: normal;
        font-size: 45px;
        letter-spacing: 0.07em;
        text-align: center;
        color: #c2915e;
        position: relative;
        display: inline-block;
        &:after{
          content: "";
          width: 98%;
          height: 4px;
          background-image: url(../img/border_title_blue.svg);
          position: absolute;
          bottom: -2px;
          background-size: cover;
          left: 0;
        }
      }

    }
  }
  .elementor-text-editor{
    font-family: $lato;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 23px;
    color: #000;

  }
  .text-white{
    .elementor-text-editor{
      color: #fff;
      max-width: 500px;
      margin: 0 auto;
    }

  }

  .elementor-button-link {
    background-color: #c2915e!important;
    border-radius: 30px;
    span{
      font-weight: 700;
      font-size: 14px;
      letter-spacing: .05em;
      line-height: 14px;
      text-align: center;
      color: #fbf7f2;
      text-transform: uppercase;
    }
  }
  #noel{
    margin-top: 100px;
    border: solid 1px #C2915E;
    border-radius: 20px;
    margin-bottom: 60px;
    padding-top: 40px;
    padding-bottom: 25px;
    .elementor-text-editor{
      width: 560px;
      max-width: 100%;
      margin: 0 auto;
    }

    .elementor-widget-image{
      left: -30px;
      position: absolute;
      height: calc(100% + 205px);
      bottom: -75px;
      width: calc(100% + 30px);
      background: linear-gradient(to left, transparent 50%, #fff 50%);
      .elementor-widget-container{
        height: 100%;
        position: relative;
        &:before{
          content:"";
          width: 110px;
          height: 110px;
          background-image: url("../img/before-img-noel.svg");
          position: absolute;
          left: -20px;
          top: -40px;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .elementor-image{
          height: 100%;
          position: relative;
          img{

            height: 100%;
            object-fit: cover;
            border-radius: 300px 300px 0 0;
          }
        }
      }
    }
  }

  .text-noel{
    width: auto;
    margin: 0 auto 20px auto;
    position: relative;
    &:before{
      content:"";
      width:115px;
      height: 165px;
      background-image: url("../img/text-noel-left.svg");
      position: absolute;
      right: -140px;
      bottom: -80px;
      background-repeat: no-repeat;
    }
    &:after{
      content:"";
      width:115px;
      height: 165px;
      background-image: url("../img/text-noel-right.svg");
      position: absolute;
      left: -140px;
      top: -25px;
    }
  }

  .text-boutique{
    position: relative;
    &:before{
      content:"";
      background-image: url("../img/after-text-boutique.svg");
      right: -240px;
      bottom: 50%;
      background-repeat: no-repeat;
      width: 200px;
      height: 260px;
      background-size: contain;
      transform: translateY(50%);
      position: absolute;
    }
    &:after{
      content:"";
      background-image: url("../img/before-text-boutique.svg");
      left: -240px;
      bottom: 50%;
      background-repeat: no-repeat;
      width: 200px;
      height: 260px;
      background-size: contain;
      transform: translateY(50%);
      position: absolute;
    }
   }

  .text-top-boutique{
    padding: 80px 15px;
    text-align: center;
    margin-top: 40px;
    position: relative;
    &:before{
      content: "";
      background: #f8f2ea;
      border-radius: 20px;
      position: absolute;
      left: -60px;
      width: calc(200% + 120px);
      height: 100%;
      top: 0;
    }
    .elementor-widget-container{
      position: relative;
    }
  }
  .img-top-boutique{
    img{
      border-radius: 300px 300px 0 0;
      max-width: 390px;
      margin: 0 auto;
      min-height: 550px;
      object-fit: cover;
    }
  }
}
.elementor-cta--skin-cover .elementor-cta-content {
  .elementor-button-text{
    color: #001253;
  }
  .elementor-button:hover{
    background: #001253 !important;
    border-color: #001253 !important;
    .elementor-button-text{
      color: #fff;
    }
  }
}
#content-wrapper{
  .all-brands{
    margin-bottom: 100px;
  }
}

#js-checkout-summary{
  background: #fbf7f2;
  border-radius: 20px;
  margin-top: 50px;
}

#js-checkout-process{
  margin-top: 50px;
  .step-number{
    font-family:$lato;
    font-weight: 900;
    font-size: 30px;
    line-height: 24px;
    text-align: center;
    text-shadow: -1px 0 #c2915e, 0 1px #c2915e, 1px 0 #c2915e, 0 -1px #c2915e;
    color: #fff;
  }
  .step-title{
    font-family: $cormorant;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    color: #001253;
    display: flex;
    align-items: center;
  }

  .js-checkout-step-header, .checkout-step .card-header{
    border-bottom: 1px dashed;
    border-color: #c3905f;
    padding-left: 0;
    padding-right: 0;

    svg{
      margin: 0 10px;
    }
  }
}

#cms, #contact{
  #content-wrapper{
    margin-bottom: 100px;
  }
}





body #lgcookieslaw_banner.lgcookieslaw-banner-floating{
  display: table;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  background: #fff;
  top: initial;
  left: initial;
  right: 10px;
  bottom: 10px;
  transform: initial;
  border-radius: 20px;
  max-width: 500px;
}

body #lgcookieslaw_banner .lgcookieslaw-banner-message p{
  color: #000 !important;
}

body #lgcookieslaw_banner .lgcookieslaw-banner-message a{
  text-decoration: underline;
  color: #000 !important;
}


body #lgcookieslaw_banner .lgcookieslaw-button-container{
  justify-content: flex-end;

  .lgcookieslaw-reject-button{
    width: auto;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    background: #e6e6e6 !important;
    color: #222222 !important;
    font-weight: 600;
    text-transform: none;
    margin-right: 15px;
  }

  .lgcookieslaw-accept-button{
    width: auto;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    background: #c3905f!important;
    font-weight: 600;
    text-transform: none;
  }
}

.title-blue{
  h2{

    font-weight: 600;
    font-size: 24px;
    letter-spacing: .07em;
    color: #001253;
    text-decoration: underline;
    margin-top: 20px;
  }
}

@media (max-width: 767px){
  #cms #noel .elementor-widget-image{
    position: initial;
    width: 100%;
    height: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  .page-content{
    padding: 0;
  }
  #cms{
    #content-wrapper{
      padding: 0;
    }
  }

  #last-post{
    max-width: 100%;
  }
  #last-post:before{
    display: none;
  }

  #last-post h2.title{
    text-align: center;
  }
  #last-post .blog-grid .block_bas h3, #last-post .blog-grid .block_bas h3 a,
  #last-post .blog-grid .block_bas .blog_desc{
    text-align: center;
  }
  #last-post .blog-grid .prestablog_more a
  {
    justify-content: center;
  }

  #last-post .blog-grid .block_top img{
    border-radius: 0;
    max-height: 325px;
  }
  .col-histoire{
    margin-top: 40px;
  }

  #cms #noel{
    margin-top: 50px;
    margin-bottom: 0;
  }

  #cms .text-top-boutique{
    padding: 30px 15px;
    text-align: center;
    margin-top: 40px;
    position: relative;
    margin-bottom: 40px;
  }

  body .first-article .block_cont{
    padding: 15px;
    flex-direction: column;
    border-radius: 5px;
    >svg{
      display: none;
    }
    .block_top{
      width: 100%;
      padding-right: 0;
      padding-bottom: 20px;
    }
    .block_bas{
      width: 100%;
    }
  }

  #blockcart-modal .modal-footer .btn-outline-secondary{
    margin-bottom: 15px;
  }
}
