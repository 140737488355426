#footer.l-footer {
  color: #001253;
  z-index: 3;
  position: relative;
  margin-top: -40px;
  background: transparent;
  //margin-top: $footer-margin-top;
  //padding-top: $footer-padding-top;
  a{
    font-weight: normal;
    font-size: 16px;
    color: #001253;
  }
  .footer__title{
    color: #001253;
  }
}

#footer{
  position: relative;
  &:before{
    content: "";
    width: 110vw;
    height: 100px;
    background: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(2deg);
    border-top: 1px solid #f8f0e8;
    z-index: -1;
  }
  /*.footer__title--desktop{
    display: none;
  }*/
  .copyright{
    margin-bottom: 0;
    text-align: center;
    background: #c2915e;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    position: relative;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    img{
      max-height: 20px;
    }
  }

  .footer-container{
    > .container{
      max-width: 1340px;

      .logo-footer{
        max-width:175px;
      }
    }
    .links-footer{
      li{
        margin-bottom: 5px;
      }
    }
  }
  .col-footer-links{
    padding-top: 60px;
  }
}


@media (min-width: 992px){
  .footer-container .col-lg-3 {
    flex: 0 0 23.333%;
    max-width: 23.333%;
  }
  .footer-container .col-lg-4 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .links-footer.Catégories{
    display: flex;
    flex-wrap: wrap;
    li{
      width: 50%;
      display: inline-block;
      svg{
        width: 20px;
        margin-right: 5px;
      }
    }
  }
}





@media (max-width: 991px){
  #footer.l-footer{
    text-align: center;
  }
  #footer .col-footer-links {
    padding-top: 10px;
  }
}
