.js-product-images-modal .slick-slider {
  touch-action: auto;
}

.images-container {
  display: flex;

  .position-relative {
    width: 80%;
    display: inline-block;
  }

  .product-thumbs {
    display: inline-block;
    height: 100%;
    padding: 0 20px;
  }

}

.product-add-to-cart {
  .qty {
    margin-left: 0 !important;
  }

  .bootstrap-touchspin {
    border: none;
    background: #FBF7F2;
    border-radius: 30px;
    padding: 8px 12px;

    .btn.js-touchspin {
      width: 24px;
      height: 24px;
      background: #C2915E;
      line-height: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 50%;
      transition-duration: 0.2s;

      &:hover {
        transition-duration: 0.2s;
        transform: scale(1.08);
      }
    }

    #quantity_wanted {
      height: 24px;
      border: none;
      background: transparent;
      font-family: $lato;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.05em;
      line-height: 23px;
      text-align: center;
      color: #c2915e;
    }
  }

  .btn-add-to-cart {
    font-family: $lato;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: .05em;
    line-height: 23px;
    color: #fff;
    text-transform: uppercase;
    padding: 7px 15px;
    border-radius: 30px;
    box-shadow: none;
    border: 1px solid #c2915e;
    background: #c2915e;
    display: flex;

    svg {
      margin-right: 10px;
      margin-top: -3px;
    }
  }

  .wishlist-button-add {
    border: 1px dotted #001253;
    box-shadow: none;
    margin-top: 1rem;
    color: #001253;

    i {
      font-weight: 300;
      color: #001253;
    }
  }
}

#product {
  h1 {
    font-family: $cormorant;
    font-weight: 600;
    font-size: 35px;
    line-height: 45px;
    color: #001253;
  }

  .product-prices {
    .product-price {
      font-family: $lato;
      font-weight: 900;
      font-size: 28px;
      color: #001253;
    }
  }

  .product-actions {
    .stock-product {
      display: inline-block;
      margin: 25px 0 15px 5px;

      .in_stock {
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        color: #c2915e;
        margin-bottom: 0;
        background: #f8f2ea;
        border-radius: 15px;
        padding: 0 12px;
        line-height: 1.4;
      }

      .out_of_stock {
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        color: #fff;
        margin-bottom: 0;
        background: #001253;
        border-radius: 15px;
        padding: 0 12px;
        white-space: nowrap;
        line-height: 1.4;
      }
    }
  }

  .sku-product {
    font-family: $lato;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.07em;
    text-align: left;
    color: #c2915e;
  }

  .products-imagescover {
    border-radius: 10px;
    border: 1px solid #f1d5a2;
    overflow: hidden;
  }

  .manufacturer-product {
    position: relative;

    &:before {
      content: "";
      width: 100%;
      height: 4px;
      background-image: url("../img/after-marque.svg");
      position: absolute;
      right: 50%;
      top: 55%;
      transform: translate(50%, -50%);
    }

    .product-manufacturer {
      display: inline-block;
      background: #fff;
      position: relative;
      z-index: 2;
      padding-right: 12px;
    }

    a {
      font-family: $lato;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.07em;
      color: #001253;
      text-transform: uppercase;
    }
  }

  #col-features {
    h2 {
      font-family: $cormorant;
      font-weight: normal;
      font-size: 35px;
      letter-spacing: 0.07em;
      color: #001253;
      position: relative;
      display: inline-block;
      margin-bottom: 30px;

      &:before {
        content: "";
        width: 98%;
        height: 4px;
        background-image: url(../img/after-marque.svg);
        position: absolute;
        bottom: -2px;
        background-size: cover;
      }
    }


    .col-feature {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .icon {
        margin-right: 10px;
        width: 25px;
      }
    }
  }
}

.product-flag.features {
  background: transparent;
  display: flex;
  img {
    width: 45px;
    height: 45px;
    margin: 0 5px;
  }
}

.product-miniature{
  .product-flag.features img{
    width: 30px;
    height: 30px;
  }
}

.product-flags {
  padding-left: 15px;

  .product-flag:not(.features) {
    background: #001253;
    text-transform: uppercase;
    font-family: $lato;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    padding: 4px 8px;
    display: flex;
    align-items: center;

    svg {
      margin: 0 5px;
    }
  }
}


#conseil {
  .content{
    max-width: 80%;
  }
  h2 {
    font-family: $cormorant;
    font-weight: normal;
    font-size: 35px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #c2915e;
    padding-top: 40px;
    position: relative;
    &:before{
      content:"";
      width: 160px;
      height: 160px;
      background-image: url("../img/conseil.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 0;
      right: 0;
    }
    &:after{
      content: "";
      width: 440px;
      height: 440px;
      background-image: url("../img/before_header.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      position: absolute;
      left: 0;
      transform: translate(-40%,-45%);
      top: 0;
      z-index: -1;
    }

    span {
      font-family: $cormorant;
      font-weight: normal;
      font-size: 35px;
      letter-spacing: 0.07em;
      text-align: center;
      color: #001253;
      position: relative;
      margin-bottom: 30px;
      display: inline-block;

      &:after {
        content: "";
        width: 98%;
        height: 4px;
        background-image: url(../img/border-title.svg);
        position: absolute;
        bottom: -2px;
        background-size: cover;
        left: 0;
      }
    }
  }

  .product-description-short{
    max-width: 400px;
    margin: 0 auto;
    *{
      font-family: $lato!important;
      font-weight: 400;
      font-size: 16px !important;
      letter-spacing: .05em;
      line-height: 23px;
      text-align: center;
      color: #001253 !important;
      background-color: transparent !important;
    }
  }

}

#same-cat-products{
  position: relative;
  z-index: 2;
  padding: 75px 0 120px;
  margin-top: 40px;
  &:before{
    content: "";
    width: 100vw;
    height: 100%;
    background: #FBF7F2;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: -1;
  }
  &:after{
    content:"";
    width: 150px;
    height: 150px;
    background-size: contain;
    background-position: top center;
    background-image: url("../img/same-cat.svg");
    position: absolute;
    right: 0;
    top:0;
  }
  h2{
    font-family: $cormorant;
    font-weight: 400;
    font-size: 45px;
    letter-spacing: .07em;
    color: #c2915e;
    line-height: 45px;
    max-width: 365px;
    margin: 0 auto 60px;
    text-transform: none;
    text-align: left;
    > span{
      font-weight: 400;
      font-size: 45px;
      letter-spacing: .07em;
      color: #001253;
      display: block;
      line-height: 45px;
      text-align: right;
      >span{
        display: inline-block;
        position: relative;
        &:before{
          content: "";
          width: 98%;
          height: 5px;
          background-image: url(../img/border-title.svg);
          position: absolute;
          bottom: -2px;
          background-size: cover;
        }
      }
    }
  }

  .products{
    border-radius: 5px;
    background: #fff;
    padding: 15px 0;
  }

}


.wishlist-add-to{
  .modal-backdrop{
    display: none !important;
  }
}

.featured-products{
  .products{
    padding: 15px 0 !important;
  }

  .slick-dots{
    display: flex;
    justify-content: center;
    li{
      margin: 0 2px;
      button{
        padding: 0;
        font-size: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #001354;
      }
      &.slick-active{
        button{
          background: #001354;
        }
      }
    }
  }
  .slick-next{
    height: auto;
    bottom: 20px;
    top: auto;
    left: calc(50% + 60px);
    right: auto;
    transform: translateX(-50%);
  }

  .slick-prev{
    height: auto;
    bottom: 20px;
    top: auto;
    left: calc(50% - 60px);
    right: auto;
    transform: translateX(-50%);
  }
  .slick-arrow{
    box-shadow: none !important;
  }
}

#product-availability{
  font-family: $lato;
  font-weight: normal;
  font-style: italic;
  font-size: 14px;
  line-height: 23px;
  color: #001253;
  margin-top: 15px;
  display: inline-block;
}

body:not(#category){
  .breadcrumb{
    padding-bottom: 10px;
    margin-bottom: 45px;
    border-bottom: 1px dashed #001253;
    font-family: $lato;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.05em;
    line-height: 23px;
    color: #001253;
    .breadcrumb-item.active{
      span{
        color: #001253;
        font-weight: bold;
      }
    }
  }
}

.images-container .product-thumbs{
  width: 20%;
}


@media (min-width: 575px) {
  .images-container .product-thumbs .product-thumb {
    height: auto;
    margin: 0;

    img {
      border: 1px solid #F1D5A2;
      border-radius: 10px;
    }

    .rc {
      background: #fff !important;
    }
  }
}

@media(max-width: 991px){
  .images-container {
    margin-bottom: 40px;
  }
  #conseil h2:after{
    display: none;
  }
  #conseil .content {
    max-width: 100%;
    margin: 0 auto;
  }
  #col-features{
    text-align: center;
  }
  #product #col-features .col-feature{
    justify-content: center;
  }
}

@media (max-width: 574px){
  #conseil h2:before{
    display: none;
  }
  .images-container .product-thumbs{
    width: 100%;
  }
  .images-container .position-relative{
    width: 100%;
  }
  .images-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  .images-container .product-thumbs .product-thumb img {
    border: 1px solid #f1d5a2;
    border-radius: 10px;
  }
}


@media (max-width: 767px){
  #same-cat-products:after{
    display: none;
  }
}