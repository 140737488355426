@charset "UTF-8";
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #001253;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "lato", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "lato", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #001253;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #000107;
    text-decoration: none; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label, .label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1,
.products-section-title, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "lato", sans-serif;
  font-weight: 500;
  line-height: 1.2; }

h1,
.h1,
.products-section-title {
  font-size: 1.375rem; }

h2, .h2 {
  font-size: 1.25rem; }

h3, .h3 {
  font-size: 1.125rem; }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 0.9375rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 1.2; }

.display-2 {
  font-size: 2.188rem;
  font-weight: 400;
  line-height: 1.2; }

.display-3 {
  font-size: 1.563rem;
  font-weight: 400;
  line-height: 1.2; }

.display-4 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small,
.quick-view {
  font-size: 0.875rem;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875rem;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row, .products {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .product-miniature, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-xs-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6, .product-miniature {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12, .col-xs-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: #fff; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #f1f1f1; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #f1f1f1; }
  .table tbody + tbody {
    border-top: 2px solid #f1f1f1; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #f1f1f1; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #f1f1f1; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #f1f1f1; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #002ed3;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 18, 83, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9); }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9); }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label, .form-inline .label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 18, 83, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 18, 83, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #001253;
  border-color: #001253;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary:hover {
    color: #fff;
    background-color: #000a2d;
    border-color: #000720; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #000a2d;
    border-color: #000720;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(38, 54, 109, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #001253;
    border-color: #001253; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #000720;
    border-color: #000413; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(38, 54, 109, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #001253;
  border-color: #001253; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #001253;
    border-color: #001253; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 18, 83, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #001253;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #001253;
    border-color: #001253; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 18, 83, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #001253;
  text-decoration: none; }
  .btn-link:hover {
    color: #000107;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #001253; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #001253;
    background-color: #001253;
    box-shadow:; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 18, 83, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #002ed3; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #073cff;
    border-color: #073cff;
    box-shadow:; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #001253;
  background-color: #001253;
  box-shadow:; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 18, 83, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 18, 83, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 18, 83, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 18, 83, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none; }
  .custom-select:focus {
    border-color: #002ed3;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 18, 83, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #002ed3;
    box-shadow: 0 0 0 0.2rem rgba(0, 18, 83, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 18, 83, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 18, 83, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 18, 83, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #001253;
    border: 0;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #073cff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #f1f1f1;
    border-color: transparent;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #001253;
    border: 0;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #073cff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #f1f1f1;
    border-color: transparent;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #001253;
    border: 0;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #073cff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-fill-lower {
    background-color: #f1f1f1; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #f1f1f1; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 2px solid #f1f1f1; }
  .nav-tabs .nav-link {
    margin-bottom: -2px;
    border: 2px solid transparent; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: transparent transparent #f1f1f1; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #001253;
    background-color: #fff;
    border-color: transparent transparent #001253; }
  .nav-tabs .dropdown-menu {
    margin-top: -2px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #001253; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.card, .page-wrapper, .block-categories, .account-links .link-item, .address {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125); }
  .card > hr, .page-wrapper > hr, .block-categories > hr, .account-links .link-item > hr, .address > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group, .page-wrapper > .list-group, .block-categories > .list-group, .account-links .link-item > .list-group, .address > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child, .page-wrapper > .list-group:first-child, .block-categories > .list-group:first-child, .account-links .link-item > .list-group:first-child, .address > .list-group:first-child {
      border-top-width: 0; }
    .card > .list-group:last-child, .page-wrapper > .list-group:last-child, .block-categories > .list-group:last-child, .account-links .link-item > .list-group:last-child, .address > .list-group:last-child {
      border-bottom-width: 0; }
  .card > .card-header + .list-group, .page-wrapper > .card-header + .list-group, .block-categories > .card-header + .list-group, .account-links .link-item > .card-header + .list-group, .address > .card-header + .list-group, .card > .page-header + .list-group, .page-wrapper > .page-header + .list-group, .block-categories > .page-header + .list-group, .account-links .link-item > .page-header + .list-group, .address > .page-header + .list-group, .card > .address-header + .list-group, .page-wrapper > .address-header + .list-group, .block-categories > .address-header + .list-group, .account-links .link-item > .address-header + .list-group, .address > .address-header + .list-group,
  .card > .list-group + .card-footer,
  .page-wrapper > .list-group + .card-footer,
  .block-categories > .list-group + .card-footer,
  .account-links .link-item > .list-group + .card-footer,
  .address > .list-group + .card-footer,
  .card > .list-group + .page-footer,
  .page-wrapper > .list-group + .page-footer,
  .block-categories > .list-group + .page-footer,
  .account-links .link-item > .list-group + .page-footer,
  .address > .list-group + .page-footer,
  .card > .list-group + .address-footer,
  .page-wrapper > .list-group + .address-footer,
  .block-categories > .list-group + .address-footer,
  .account-links .link-item > .list-group + .address-footer,
  .address > .list-group + .address-footer {
    border-top: 0; }

.card-body, .page-content, .block-categories, .address-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header, .page-header, .address-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125); }

.card-footer, .page-footer, .address-footer {
  padding: 0.75rem 1.25rem;
  background-color: transparent;
  border-top: 0 solid rgba(0, 0, 0, 0.125); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-deck .card, .card-deck .page-wrapper, .card-deck .block-categories, .card-deck .account-links .link-item, .account-links .card-deck .link-item, .card-deck .address {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card, .card-deck .page-wrapper, .card-deck .block-categories, .card-deck .account-links .link-item, .account-links .card-deck .link-item, .card-deck .address {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card, .card-group > .page-wrapper, .card-group > .block-categories, .account-links .card-group > .link-item, .card-group > .address {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card, .card-group > .page-wrapper, .card-group > .block-categories, .account-links .card-group > .link-item, .card-group > .address {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card, .card-group > .page-wrapper + .card, .card-group > .block-categories + .card, .account-links .card-group > .link-item + .card, .card-group > .address + .card, .card-group > .card + .page-wrapper, .card-group > .page-wrapper + .page-wrapper, .card-group > .block-categories + .page-wrapper, .account-links .card-group > .link-item + .page-wrapper, .card-group > .address + .page-wrapper, .card-group > .card + .block-categories, .card-group > .page-wrapper + .block-categories, .card-group > .block-categories + .block-categories, .account-links .card-group > .link-item + .block-categories, .card-group > .address + .block-categories, .account-links .card-group > .card + .link-item, .account-links .card-group > .page-wrapper + .link-item, .account-links .card-group > .block-categories + .link-item, .account-links .card-group > .link-item + .link-item, .account-links .card-group > .address + .link-item, .card-group > .card + .address, .card-group > .page-wrapper + .address, .card-group > .block-categories + .address, .account-links .card-group > .link-item + .address, .card-group > .address + .address {
        margin-left: 0;
        border-left: 0; } }

.card-columns .card, .card-columns .page-wrapper, .card-columns .block-categories, .card-columns .account-links .link-item, .account-links .card-columns .link-item, .card-columns .address {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card, .card-columns .page-wrapper, .card-columns .block-categories, .card-columns .account-links .link-item, .account-links .card-columns .link-item, .card-columns .address {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card, .accordion > .page-wrapper, .accordion > .block-categories, .account-links .accordion > .link-item, .accordion > .address {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type), .accordion > .page-wrapper:not(:last-of-type), .accordion > .block-categories:not(:last-of-type), .account-links .accordion > .link-item:not(:last-of-type), .accordion > .address:not(:last-of-type) {
      border-bottom: 0; }
    .accordion > .card > .card-header, .accordion > .page-wrapper > .card-header, .accordion > .block-categories > .card-header, .account-links .accordion > .link-item > .card-header, .accordion > .address > .card-header, .accordion > .card > .page-header, .accordion > .page-wrapper > .page-header, .accordion > .block-categories > .page-header, .account-links .accordion > .link-item > .page-header, .accordion > .address > .page-header, .accordion > .card > .address-header, .accordion > .page-wrapper > .address-header, .accordion > .block-categories > .address-header, .account-links .accordion > .link-item > .address-header, .accordion > .address > .address-header {
      margin-bottom: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination, .product-comments__pagination ul {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link, .product-comments__pagination li span {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #001253;
  background-color: #fff;
  border: 1px solid #ced4da; }
  .page-link:hover, .product-comments__pagination li span:hover {
    z-index: 2;
    color: #000107;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #ced4da; }
  .page-link:focus, .product-comments__pagination li span:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 18, 83, 0.25); }

.page-item:first-child .page-link, .product-comments__pagination li:first-child .page-link, .page-item:first-child .product-comments__pagination li span, .product-comments__pagination li .page-item:first-child span, .product-comments__pagination li:first-child span {
  margin-left: 0; }

.page-item.active .page-link, .product-comments__pagination li.active .page-link, .page-item.active .product-comments__pagination li span, .product-comments__pagination li .page-item.active span, .product-comments__pagination li.active span {
  z-index: 3;
  color: #fff;
  background-color: #001253;
  border-color: #001253; }

.page-item.disabled .page-link, .product-comments__pagination li.disabled .page-link, .page-item.disabled .product-comments__pagination li span, .product-comments__pagination li .page-item.disabled span, .product-comments__pagination li.disabled span {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #ced4da; }

.pagination-lg .page-link, .pagination-lg .product-comments__pagination li span, .product-comments__pagination li .pagination-lg span {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-sm .page-link, .product-comments__pagination ul .page-link, .pagination-sm .product-comments__pagination li span, .product-comments__pagination li .pagination-sm span, .product-comments__pagination ul li span, .product-comments__pagination li ul span {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #00092b;
  background-color: #ccd0dd;
  border-color: #b8bdcf; }
  .alert-primary hr {
    border-top-color: #a9afc5; }
  .alert-primary .alert-link {
    color: black; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #001253;
    border-color: #001253; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush > .list-group-item {
  border-width: 0 0 1px; }
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #f1f1f1; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #f1f1f1; }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 990px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border, .rc:after, .btn-add-to-cart .btn-add-to-cart__spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .rc:after, .btn-add-to-cart .btn-add-to-cart__spinner,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #001253 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #000720 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #f1f1f1 !important; }

.border-top {
  border-top: 1px solid #f1f1f1 !important; }

.border-right {
  border-right: 1px solid #f1f1f1 !important; }

.border-bottom {
  border-bottom: 1px solid #f1f1f1 !important; }

.border-left {
  border-left: 1px solid #f1f1f1 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #001253 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .social-sharing, .pack-product-container, .pack-product-quantity {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap, .social-sharing {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1, .pack-product-name {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between, .pack-product-container {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center, .social-sharing--product, .pack-product-container, .pack-product-quantity {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch, .account-links {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch, .pack-product-quantity {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left, .float-xs-left {
  float: left !important; }

.float-right, .float-xs-right {
  float: right !important; }

.float-none, .float-xs-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm, .social-share-btn--product {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4,
.pack-product-name {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4,
.pack-product-name {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #001253 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #000107 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container, .l-wrapper, main > .notifications-container, .l-wrapper--boxed {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #f1f1f1 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #f1f1f1; }
  .table .thead-dark th {
    color: inherit;
    border-color: #f1f1f1; } }

/* HEADER */
/* MAIN */
/* FOOTER */
/* PRODUCT MINIATURE */
/* product title */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format("woff2"), url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format("woff"), url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format("truetype"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  /* Preferred icon size */
  display: inline-block;
  vertical-align: top;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

.material-icons + span {
  display: inline-block; }

.small .material-icons, .quick-view .material-icons,
.btn-sm .material-icons,
.btn-group-sm > .btn .material-icons {
  font-size: 0.875rem;
  line-height: 1.5; }

.btn-lg .material-icons, .btn-group-lg > .btn .material-icons {
  font-size: 1.875rem; }

.pagination .material-icons, .product-comments__pagination ul .material-icons {
  font-size: 1.25rem; }

.material-icons.md-18 {
  font-size: 18px;
  line-height: 1; }

.material-icons.md-24 {
  font-size: 24px;
  line-height: 1; }

.material-icons.md-36 {
  font-size: 36px;
  line-height: 1; }

.material-icons.md-48 {
  font-size: 48px;
  line-height: 1; }

.hidden-xs-up {
  display: none !important; }

@media (max-width: 575.98px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 767.98px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 991.98px) {
  .hidden-md-down {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1199.98px) {
  .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

body {
  background-color: #fff; }

a {
  text-decoration: none; }

sup {
  top: -0.3em; }

.page-header h1 {
  margin-bottom: 0; }

.page-footer:empty {
  display: none; }

.left-column > div,
.right-column > div {
  margin-bottom: 1rem; }

/* MISC */
ul {
  list-style: none;
  padding-left: 0; }

.quick-view {
  display: inline-block; }

.form-group.form-group_forgot-password {
  margin-top: -0.5rem; }

.bootstrap-touchspin {
  border: 1px solid #ced4da; }
  .bootstrap-touchspin > input {
    max-width: 60px;
    border: none;
    box-shadow: none;
    text-align: center;
    font-weight: 700; }
    .bootstrap-touchspin > input::-webkit-outer-spin-button, .bootstrap-touchspin > input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  .bootstrap-touchspin .btn {
    position: relative;
    z-index: 2;
    color: #495057;
    background-color: #fff; }
  .bootstrap-touchspin .input-group-btn {
    display: flex; }
    .bootstrap-touchspin .input-group-btn:first-child {
      margin-right: -1px; }
    .bootstrap-touchspin .input-group-btn:last-child {
      margin-left: -1px; }

/* minimizing reflows and avoiding page jumps */
.rc {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  background: rgba(0, 0, 0, 0.05);
  transition: background 150ms; }
  .rc:after {
    content: "";
    border-color: #f1f1f1;
    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
    z-index: 2;
    border-right-color: transparent; }
  .rc--lazyload:after {
    display: none; }

.rc > * {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.rc > a {
  display: block; }

.ratio2_1 {
  padding-top: 50%; }

.ratio1_2 {
  padding-top: 200%; }

.ratio4_3 {
  padding-top: 75%; }

.ratio16_9 {
  padding-top: 56.25%; }

.ratio1_1 {
  padding-top: 100%; }

.ratio3_2 {
  padding-top: 33.33333%; }

/* collapse icons */
.icon-collapse, .link__showsummary .material-icons {
  transition: transform 0.4s ease; }

.icon-collapse[aria-expanded=true], .link__showsummary .material-icons[aria-expanded=true] {
  transform: rotateZ(180deg); }

/* CHECKOUT STEP */
.order-checkout .card-body, .order-checkout .page-content, .order-checkout .block-categories, .order-checkout .address-body {
  padding-bottom: 0; }

.step-edit[aria-expanded="true"] {
  display: none; }

.step-title {
  margin-bottom: 0; }

.-complete.-reachable .step-title .done {
  display: inline-block; }

.-current .step-title .done {
  visibility: hidden; }

.-current .card-header, .-current .page-header, .-current .address-header {
  background-color: #fff; }

.accordion .card-body .card, .accordion .page-content .card, .accordion .block-categories .card, .accordion .address-body .card, .accordion .card-body .page-wrapper, .accordion .page-content .page-wrapper, .accordion .block-categories .page-wrapper, .accordion .address-body .page-wrapper, .accordion .card-body .block-categories, .accordion .page-content .block-categories, .accordion .block-categories .block-categories, .accordion .address-body .block-categories, .accordion .card-body .account-links .link-item, .account-links .accordion .card-body .link-item, .accordion .page-content .account-links .link-item, .account-links .accordion .page-content .link-item, .accordion .block-categories .account-links .link-item, .account-links .accordion .block-categories .link-item, .accordion .address-body .account-links .link-item, .account-links .accordion .address-body .link-item, .accordion .card-body .address, .accordion .page-content .address, .accordion .block-categories .address, .accordion .address-body .address {
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: border 0.1s linear; }

.accordion .card-body .card-header, .accordion .page-content .card-header, .accordion .block-categories .card-header, .accordion .address-body .card-header, .accordion .card-body .page-header, .accordion .page-content .page-header, .accordion .block-categories .page-header, .accordion .address-body .page-header, .accordion .card-body .address-header, .accordion .page-content .address-header, .accordion .block-categories .address-header, .accordion .address-body .address-header {
  text-align: center; }

.accordion .selected .card, .accordion .selected .page-wrapper, .accordion .selected .block-categories, .accordion .selected .account-links .link-item, .account-links .accordion .selected .link-item, .accordion .selected .address {
  border-color: #28a745;
  border-width: 2px; }

.nav-tabs-info {
  font-size: 1.1rem; }
  .nav-tabs-info .active {
    font-weight: bold; }

.address-selector .card, .address-selector .page-wrapper, .address-selector .block-categories, .address-selector .account-links .link-item, .account-links .address-selector .link-item, .address-selector .address {
  transition: border 0.1s linear; }

.address-selector .card-header, .address-selector .page-header, .address-selector .address-header,
.address-selector .card-body,
.address-selector .page-content,
.address-selector .block-categories,
.address-selector .address-body {
  cursor: pointer; }

.card-body_add-address {
  padding: 0.75rem 1.25rem; }
  .card-body_add-address .material-icons {
    opacity: .8; }

.delivery-option {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #e9ecef; }

.carrier-extra-content:not(:empty) {
  margin-bottom: 2rem; }
  .carrier-extra-content:not(:empty):empty {
    display: none; }

.carrier-name {
  font-weight: 700; }

.carrier-price {
  font-weight: bold;
  font-size: 1.25rem;
  color: #001253; }

.order-line:not(:last-child) {
  margin-bottom: 0.5rem; }

.order-confirmation-table {
  padding: 1rem;
  border: 3px solid rgba(0, 0, 0, 0.125); }

.custom-file-label.selected:after {
  content: "" !important; }

.nav-tabs .nav-link {
  color: #6c757d;
  font-weight: 700; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-bottom-width: 3px; }

.tab-content {
  padding-top: 1rem; }

.products-section-title {
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin-bottom: 1rem; }

.page-home > * {
  margin-bottom: 2rem; }

@media (max-width: 991.98px) {
  .modal-footer {
    flex-direction: column-reverse; }
    .modal-footer > .btn {
      margin: 0;
      width: 100%; }
    .modal-footer .btn:not(:last-child) {
      margin-top: 0.5rem; } }

.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .nav-tabs::-webkit-scrollbar {
    display: none; }
  .nav-tabs .nav-link {
    white-space: nowrap; }

.notifications-container ul {
  margin-bottom: 0; }

.js-invalid-feedback-browser:empty {
  display: none; }

.form-group label small, .form-group .label small,
.form-group label .small,
.form-group .label .small,
.form-group label .quick-view,
.form-group .label .quick-view, .form-group .label small,
.form-group .label .small,
.form-group .label .quick-view {
  font-size: 0.75rem; }

@media (min-width: 992px) {
  .page-content form,
  .checkout-form form,
  .page-wrapper--order-confirmation form {
    width: 75%;
    max-width: 450px;
    margin: 0 auto; }
  .page-wrapper--order-detail form {
    margin: unset; } }

.form-footer {
  margin-top: 1rem; }

.badge.bright {
  color: #fff; }

.no-js img.lazyload {
  display: none; }

.lazyload,
.lazyloading {
  opacity: 0; }

.lazyloaded {
  opacity: 1;
  transition: opacity 500ms; }

.slick-slide.lazyload {
  display: none !important; }

.slick-slide .lazyload {
  display: none !important; }

.slick-active.lazyload,
.slick-current.lazyload,
.slick-current + .slick-slide.lazyload {
  display: block !important; }

.slick-active .lazyload,
.slick-current .lazyload,
.slick-current + .slick-slide .lazyload {
  display: block !important; }

@media screen and (max-width: 992px) {
  #blockcart-modal .modal-footer {
    flex-direction: row; } }

@media screen and (max-width: 769px) {
  .checkout-process .nav-tabs {
    flex-wrap: wrap;
    overflow: visible; }
    .checkout-process .nav-tabs .nav-item {
      flex: 0 0 100%;
      text-align: center; }
  #blockcart-modal .modal-footer {
    padding-bottom: 2rem; }
  .checkout-step .continue {
    display: block;
    width: 100%; } }

/** LAYOUT **/
.l-header {
  background-color: #fff;
  margin-bottom: 1rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); }

.header__up {
  align-content: center; }

.header-top {
  flex-wrap: wrap;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.header__search {
  flex: 1;
  padding-right: 15px;
  padding-left: 15px; }

.header__right,
.header-nav__right {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.blockcart a,
.user-info a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center; }

.blockcart i,
.user-info i {
  flex: 0 0 100%;
  font-size: 36px; }

.header__rightitem:not(:last-child) {
  margin-right: 0.5rem; }

.header__logo--checkout {
  max-width: 200px; }

.header-nav--checkout {
  padding-top: 1rem;
  padding-bottom: 1rem; }

@media (max-width: 991.98px) {
  .header__logo,
  .header__logo--checkout {
    max-width: 50%; }
  /*.header__search {
		width: 100%;
		padding: 0;
		flex: auto;
		order: 10;
		margin-top: 10px;
	}*/
  .header__logo--checkout {
    margin: 0 auto; } }

@media (min-width: 992px) {
  .header-top__col {
    width: 25%; } }

@media (min-width: 768px) {
  .header-top__col {
    width: 25%; } }

#header-sticky {
  position: relative;
  z-index: 1020;
  top: auto;
  right: 0;
  left: 0; }
  #header-sticky.sticky .container-menu {
    position: fixed;
    top: 0;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 100%; }

@media screen and (min-width: 1200px) {
  #header {
    min-height: 308px; }
    #header .sticky .header-top__col img {
      max-height: 37.5px;
      width: auto; } }

@media screen and (max-width: 1200px) {
  #header {
    min-height: 138px; }
  /*#header-sticky {
		&.sticky {
			background: rgba(#fff, 0.85);
			backdrop-filter: blur(5px);
		}
	}*/ }

@media (min-width: 992px) {
  #header-sticky .user-info:hover ul {
    display: block !important;
    position: absolute;
    z-index: 10;
    padding: 10px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    background: #fff;
    overflow: hidden; } }

#index #header {
  margin-bottom: 0; }

#header .header-banner {
  position: relative;
  z-index: 1021; }

#header .header__search {
  text-align: center; }
  #header .header__search .logo {
    margin-bottom: 20px; }

#header #search_widget {
  float: none;
  max-width: 460px;
  margin: 0 auto; }
  #header #search_widget input {
    border: 1px solid #001253;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #001253;
    padding: 5px 40px 5px 20px;
    border-radius: 30px;
    background: #fff; }
    #header #search_widget input::placeholder {
      color: #001253; }
  #header #search_widget i.search {
    right: 10px;
    top: 50%;
    font-size: 0;
    transform: translateY(-50%);
    width: 19px;
    height: 19px;
    background-image: url("../img/np_magic.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }

#header .ek_infos-container {
  background: #001253; }
  #header .ek_infos-container p, #header .ek_infos-container * {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #fff; }
  #header .ek_infos-container .caption > div {
    display: flex;
    align-items: center;
    justify-content: center; }
    #header .ek_infos-container .caption > div:before, #header .ek_infos-container .caption > div:after {
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../img/pseudo_nav.svg");
      margin: 0 10px; }
  #header .ek_infos-container .slick-slide {
    padding: 5px; }

#header .header-top {
  padding: 40px 15px;
  align-items: end !important; }
  #header .header-top .header_left {
    padding-right: 50px; }
    #header .header-top .header_left .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: end; }
      #header .header-top .header_left .content .address_header {
        margin-bottom: 30px;
        font-weight: normal;
        font-size: 14px;
        color: #001253;
        display: flex;
        align-items: center;
        position: relative; }
        #header .header-top .header_left .content .address_header:before {
          content: "";
          width: 440px;
          height: 440px;
          background-image: url("../img/before_header.png");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          left: 0;
          transform: translate(-82%, -4%); }
        #header .header-top .header_left .content .address_header img {
          margin-right: 10px; }
      #header .header-top .header_left .content .horraires_header {
        margin-right: 50px;
        font-weight: normal;
        font-size: 14px;
        color: #001253;
        display: flex;
        align-items: center; }
        #header .header-top .header_left .content .horraires_header img {
          margin-right: 10px; }

#header .container, #header .l-wrapper, #header main > .notifications-container, #header .l-wrapper--boxed {
  max-width: 1360px; }

#header .header__rightitem:not(:last-child) {
  margin-right: 30px; }

#header .header__right {
  flex-direction: column;
  align-items: flex-end; }
  #header .header__right .contact_header {
    margin-top: 20px; }
    #header .header__right .contact_header a {
      font-weight: 500;
      font-size: 14px;
      color: #c2915e;
      display: flex;
      align-items: center; }
      #header .header__right .contact_header a img {
        margin-right: 8px; }
  #header .header__right .links_header {
    display: flex; }
    #header .header__right .links_header span {
      font-weight: 600;
      font-size: 16px;
      color: #001253; }
  #header .header__right .blockcart a, #header .header__right .user-info a {
    flex-direction: column; }
    #header .header__right .blockcart a img, #header .header__right .user-info a img {
      height: 60px;
      width: 60px;
      object-fit: contain;
      object-position: center;
      margin: 0 auto; }
    #header .header__right .blockcart a .cart-products-count, #header .header__right .user-info a .cart-products-count {
      display: none; }

#header .ets_mm_megamenu_content {
  border: none;
  background: transparent;
  position: relative; }
  #header .ets_mm_megamenu_content:before {
    content: "";
    width: 100vw;
    height: 100%;
    background: #FBF7F2;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%); }
  #header .ets_mm_megamenu_content .ets_mm_megamenu_content_content > .mm_menus_ul {
    padding: 10px 0;
    justify-content: center;
    display: flex; }
    #header .ets_mm_megamenu_content .ets_mm_megamenu_content_content > .mm_menus_ul .mm_menus_li {
      border: none; }
      #header .ets_mm_megamenu_content .ets_mm_megamenu_content_content > .mm_menus_ul .mm_menus_li:hover a {
        background: transparent; }
      #header .ets_mm_megamenu_content .ets_mm_megamenu_content_content > .mm_menus_ul .mm_menus_li .mm_menu_content_title {
        font-weight: 500;
        font-size: 16px;
        color: #001253;
        padding: 0;
        text-transform: initial;
        margin: 0 10px; }
        #header .ets_mm_megamenu_content .ets_mm_megamenu_content_content > .mm_menus_ul .mm_menus_li .mm_menu_content_title > img {
          max-height: 24px; }
      #header .ets_mm_megamenu_content .ets_mm_megamenu_content_content > .mm_menus_ul .mm_menus_li.brands .mm_menu_content_title {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.03em;
        color: #fff;
        border-radius: 13px;
        background: #c2915e;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        padding: 5px 12px;
        line-height: 16px; }
      #header .ets_mm_megamenu_content .ets_mm_megamenu_content_content > .mm_menus_ul .mm_menus_li.ages .mm_menu_content_title {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.03em;
        color: #fff;
        border-radius: 13px;
        background: #001253;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        padding: 5px 12px;
        line-height: 16px; }

#blockcart-modal .product-image {
  border-radius: 10px;
  border: 1px solid #f1d5a2;
  padding: 1px; }

#blockcart-modal .col-delete {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; }

#blockcart-modal .product-title {
  font-family: "lato", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #001253; }

#blockcart-modal .product-qty {
  font-family: "lato", sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #c2915e;
  margin-top: 10px; }
  #blockcart-modal .product-qty .qty {
    font-weight: normal; }

#blockcart-modal .price {
  font-family: "lato", sans-serif;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #001253; }

#blockcart-modal .remove-from-cart {
  color: #001253; }

#blockcart-modal .border-bottom {
  border-bottom: 1px dashed #f1d5a2 !important;
  border-top: none !important; }

#blockcart-modal .modal-header p.modal-title {
  font-family: "lato", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #000; }

#blockcart-modal .modal-header .close {
  opacity: 1; }
  #blockcart-modal .modal-header .close span {
    display: flex;
    align-items: center; }

#blockcart-modal .modal-footer {
  border-top: none;
  position: relative; }
  #blockcart-modal .modal-footer:before {
    content: "";
    width: calc(100% - 30px);
    height: 4px;
    background-image: url(../img/border-modal-cart.svg);
    position: absolute;
    top: -2px;
    background-size: cover;
    left: 15px; }
  #blockcart-modal .modal-footer .btn-outline-secondary {
    font-family: "lato", sans-serif;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 24px;
    text-align: center;
    color: #c2915e;
    padding: 11px 15px;
    border: 1px #c2915e solid;
    border-radius: 30px; }
    #blockcart-modal .modal-footer .btn-outline-secondary:hover {
      color: #fff;
      background: #c2915e; }
  #blockcart-modal .modal-footer .btn-primary {
    font-family: "lato", sans-serif;
    font-weight: 900;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 23px;
    color: #fff;
    text-transform: uppercase;
    padding: 11px 15px;
    border-radius: 30px;
    box-shadow: none;
    border: 1px solid #c2915e;
    background: #c2915e; }
    #blockcart-modal .modal-footer .btn-primary:hover {
      color: #fff;
      background: #001253;
      border-color: #001253; }
  #blockcart-modal .modal-footer p.font-weight-bold {
    font-family: "lato", sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #001253; }

#wrapper {
  max-width: 1360px; }

#np_star_2952957_000000 {
  animation-name: blink;
  animation-duration: 4s;
  animation-iteration-count: infinite; }

#np_star_2016468_000000-2 {
  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite; }

#np_star_2016468_000000-3, #Tracé_819 {
  animation-name: blink;
  animation-duration: 3s;
  animation-iteration-count: infinite; }

#Tracé_821 {
  animation-name: blink;
  animation-duration: 5s;
  animation-iteration-count: infinite; }

#np_star_2016468_000000, #Tracé_820 {
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite; }

#Tracé_818 {
  animation-name: blink;
  animation-duration: 5s;
  animation-iteration-count: infinite; }

@keyframes blink {
  0% {
    opacity: 0; }
  25% {
    opacity: .5; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0.5; }
  100% {
    opacity: 0; } }

.jolisearch-body {
  max-height: 80vh;
  overflow-y: scroll !important; }
  .jolisearch-body .jolisearch-content > div {
    display: flex;
    flex-wrap: wrap; }

.left-header-mobile {
  display: none; }

@media (max-width: 1280px) {
  #header .ets_mm_megamenu_content .ets_mm_megamenu_content_content > .mm_menus_ul .mm_menus_li .mm_menu_content_title > img {
    display: none; } }

@media (max-width: 1090px) {
  #header .header-top {
    padding: 10px 15px 25px; }
  .header__search {
    padding-right: 50px; }
  svg.logo {
    max-width: 100%; }
  #header .header__right .contact_header a {
    font-size: 13px; } }

@media (max-width: 1115px) {
  #header .header__right .links_header span {
    font-size: 14px; }
  #header .header__rightitem:not(:last-child) {
    margin-right: 20px; } }

@media (max-width: 991px) {
  #header .header-top .header_left .content .address_header:before {
    display: none; }
  #header .header-top .header_left .content .horraires_header {
    margin-right: 0; }
  #header .header-top .header_left .content {
    align-items: baseline; } }

@media (max-width: 767px) {
  .header__search {
    width: 100%;
    padding: 0;
    flex: auto;
    order: 10;
    margin-top: 10px; }
  #header .header__search .logo {
    display: none; }
  .header-top__col .contact_header {
    display: none; }
  .header_left {
    display: none; }
  .left-header-mobile {
    display: flex;
    align-items: center;
    height: 100%; }
    .left-header-mobile .ybc-menu-toggle {
      display: inline-block;
      width: auto;
      padding-left: 0; }
  .container-menu .ybc-menu-toggle {
    height: 0;
    opacity: 0;
    padding: 0; }
  #header .header-top {
    align-items: center !important; }
  #header .header__right .blockcart a img, #header .header__right .user-info a img {
    height: 45px; }
  #header .header__rightitem:not(:last-child) {
    margin-right: 5px; }
  #header {
    min-height: 185px; }
  #header-sticky.sticky {
    backdrop-filter: none; }
    #header-sticky.sticky .header-top__col_sticky {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100;
      background: #fff;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); }
    #header-sticky.sticky .header__search {
      display: none; }
    #header-sticky.sticky .header-top {
      padding: 10px 15px 10px; }
  #header-sticky.sticky .container-menu {
    z-index: 1000;
    height: 0; } }

#header .changestatus .ets_mm_megamenu_content .ets_mm_megamenu_content_content > .mm_menus_ul {
  display: block;
  padding-top: 32px; }

#header .changestatus .pull-right .mm_menus_back_icon.first {
  transform: rotate(135deg);
  margin-right: -2px; }

#header .changestatus .close_menu {
  background-color: #001253 !important; }

#header .sticky .changestatus .ets_mm_megamenu_content .ets_mm_megamenu_content_content > .mm_menus_ul {
  padding-top: 0px; }

.mm_menus_ul .mm_menus_li {
  position: relative; }
  .mm_menus_ul .mm_menus_li.sub_right .mm_columns_ul {
    right: 0;
    left: auto;
    display: flex;
    justify-content: end; }

.mm_columns_ul {
  padding-top: 0 !important;
  border: none !important;
  background-color: transparent !important;
  width: 100vw !important; }
  .mm_columns_ul .mm_columns_li.column_size_12 {
    display: inline-block;
    width: auto; }
    .mm_columns_ul .mm_columns_li.column_size_12 .ek_menu {
      background: #fff;
      padding: 15px;
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
      width: calc(100% + 80px); }
      .mm_columns_ul .mm_columns_li.column_size_12 .ek_menu .row .ek_sub_menu:first-child, .mm_columns_ul .mm_columns_li.column_size_12 .ek_menu .products .ek_sub_menu:first-child {
        position: relative; }
        .mm_columns_ul .mm_columns_li.column_size_12 .ek_menu .row .ek_sub_menu:first-child:before, .mm_columns_ul .mm_columns_li.column_size_12 .ek_menu .products .ek_sub_menu:first-child:before {
          content: "";
          width: 3px;
          height: 100%;
          background-image: url("../img/border_sub_menu.svg");
          position: absolute;
          right: -2px;
          top: 0;
          background-size: contain; }

.ek_sub_menu ul li a {
  display: flex;
  align-items: center;
  padding: 10px; }
  .ek_sub_menu ul li a img {
    width: 24px;
    height: 100%;
    margin-right: 10px;
    max-height: 26px;
    object-fit: contain; }

.ek_sub_menu ul li.all {
  position: absolute;
  bottom: 0;
  right: 20px; }
  .ek_sub_menu ul li.all a span {
    font-family: "lato", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #c2915e;
    display: flex;
    align-items: center; }
    .ek_sub_menu ul li.all a span svg {
      margin-left: 10px; }

.mm_menu_content_title .mm_arrow {
  display: none; }

.changestatus .mm_menus_ul .mm_menus_li.sub_right .mm_columns_ul {
  display: none; }

.changestatus .mm_columns_ul .mm_columns_li.column_size_12 .ek_menu .row .ek_sub_menu:first-child:before, .changestatus .mm_columns_ul .mm_columns_li.column_size_12 .ek_menu .products .ek_sub_menu:first-child:before {
  display: none; }

.changestatus .ek_sub_menu ul li.all {
  position: initial; }

.changestatus .mm_columns_ul .mm_columns_li.column_size_12 .ek_menu .col-md-6 {
  flex: 0 0 100%;
  max-width: 100%; }

.changestatus .mm_columns_ul .mm_columns_li.column_size_12 .ek_menu {
  max-width: 100%; }

#index #header {
  margin-bottom: 0 !important; }

#footer.l-footer {
  color: #001253;
  z-index: 3;
  position: relative;
  margin-top: -40px;
  background: transparent; }
  #footer.l-footer a {
    font-weight: normal;
    font-size: 16px;
    color: #001253; }
  #footer.l-footer .footer__title {
    color: #001253; }

#footer {
  position: relative;
  /*.footer__title--desktop{
    display: none;
  }*/ }
  #footer:before {
    content: "";
    width: 110vw;
    height: 100px;
    background: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(2deg);
    border-top: 1px solid #f8f0e8;
    z-index: -1; }
  #footer .copyright {
    margin-bottom: 0;
    text-align: center;
    background: #c2915e;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    position: relative;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%); }
    #footer .copyright img {
      max-height: 20px; }
  #footer .footer-container > .container, #footer .footer-container > .l-wrapper, #footer main.footer-container > .notifications-container, #footer .footer-container > .l-wrapper--boxed {
    max-width: 1340px; }
    #footer .footer-container > .container .logo-footer, #footer .footer-container > .l-wrapper .logo-footer, #footer main.footer-container > .notifications-container .logo-footer, #footer .footer-container > .l-wrapper--boxed .logo-footer {
      max-width: 175px; }
  #footer .footer-container .links-footer li {
    margin-bottom: 5px; }
  #footer .col-footer-links {
    padding-top: 60px; }

@media (min-width: 992px) {
  .footer-container .col-lg-3 {
    flex: 0 0 23.333%;
    max-width: 23.333%; }
  .footer-container .col-lg-4 {
    flex: 0 0 30%;
    max-width: 30%; }
  .links-footer.Catégories {
    display: flex;
    flex-wrap: wrap; }
    .links-footer.Catégories li {
      width: 50%;
      display: inline-block; }
      .links-footer.Catégories li svg {
        width: 20px;
        margin-right: 5px; } }

@media (max-width: 991px) {
  #footer.l-footer {
    text-align: center; }
  #footer .col-footer-links {
    padding-top: 10px; } }

.menu {
  padding-bottom: 1rem; }

.menu-top {
  display: flex;
  justify-content: center;
  margin: 0;
  position: relative; }

.menu__item-link--top {
  color: #343a40;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  padding: 0.5rem; }

@media (min-width: 992px) {
  .menu__item-header {
    height: 100%; }
  .menu-sub {
    position: absolute;
    opacity: 0;
    width: 100%;
    left: 0;
    top: 100%;
    z-index: -1;
    transition: all .5s ease; }
  .menu-sub__content > ul {
    padding: 10px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2); }
  .menu__item--active .menu-sub {
    z-index: 2;
    opacity: 1; }
  .menu-sub__list--1 {
    display: flex; }
  .menu__item--1 {
    padding-right: 10px;
    padding-left: 10px; }
  .menu__item-link--1 {
    text-transform: uppercase;
    color: #000; }
    .menu__item-link--1 .menu__item-link {
      color: #212529; } }

@media (max-width: 991.98px) {
  .menu-top {
    flex-direction: column;
    margin-bottom: 1rem; }
  .menu-sub {
    width: 100%;
    transition: all .3s ease-out; }
  .menu-sub__content {
    padding: 0; }
  .menu__item-link--top {
    padding: 0; }
  .top-menu-mobile {
    margin-right: -1rem;
    margin-left: -1rem; }
  .menu__item {
    border-bottom: 1px solid #f1f1f1;
    border-top: 1px solid #f1f1f1; }
  .menu__item--top:not(:first-child) {
    border-top: 0; }
  .menu__item--sub:last-child,
  .menu__item--sub:first-child:not(:last-child) {
    border-bottom: 0; }
  .menu__item-header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem; }
  .menu__item-link--nosubmenu {
    padding: 0.5rem;
    display: block; }
  .menu-sub.show {
    background: #fff; }
  .menu__item--1 .menu__item-link--nosubmenu,
  .menu__item--1 .menu__item-header {
    margin-left: 10px; }
  .menu__item--2 .menu__item-link--nosubmenu,
  .menu__item--2 .menu__item-header {
    margin-left: 20px; }
  .menu__item--3 .menu__item-link--nosubmenu,
  .menu__item--3 .menu__item-header {
    margin-left: 30px; } }

#mobile_top_menu_wrapper.modal .modal-header {
  border-bottom: 0; }

#mobile_top_menu_wrapper.modal .modal-body {
  padding-top: 0; }

.menu__collapseicon {
  width: 3rem;
  height: 1.5rem;
  display: block;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center; }

/** PAGES **/
#blockcart-modal .product-quantity .input-group {
  max-width: 130px; }

#cart .title-cart {
  position: relative; }
  #cart .title-cart:before {
    content: "";
    width: 100%;
    height: 4px;
    background-image: url("../img/border-title-cart.svg");
    position: absolute;
    top: 55%;
    transform: translateY(-50%); }
  #cart .title-cart h1 {
    font-family: "cormorant-garamond", serif;
    font-weight: bold;
    font-size: 35px;
    line-height: 16px;
    color: #001253;
    margin-top: 40px;
    margin-bottom: 40px;
    display: inline-block;
    position: relative;
    background: #fff;
    padding-right: 15px; }

#cart .product-line-grid .media img {
  border-radius: 10px;
  border: 1px solid #f1d5a2;
  padding: 1px; }

#cart .product-line-grid .product-line__title {
  font-family: "lato", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #001253;
  margin-bottom: 5px; }

#cart .product-line-grid .product-ref {
  font-family: "lato", sans-serif;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.07em;
  color: #c2915e;
  margin-bottom: 0; }

#cart .product-line-grid .product-line-info {
  margin-top: 30px; }

#cart .product-line-grid .bootstrap-touchspin {
  border: none;
  background: #FBF7F2;
  border-radius: 30px;
  padding: 8px 12px; }
  #cart .product-line-grid .bootstrap-touchspin .btn.js-touchspin {
    width: 24px;
    height: 24px;
    background: #C2915E;
    line-height: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    transition-duration: 0.2s; }
    #cart .product-line-grid .bootstrap-touchspin .btn.js-touchspin:hover {
      transition-duration: 0.2s;
      transform: scale(1.08); }
  #cart .product-line-grid .bootstrap-touchspin .js-cart-line-product-quantity {
    height: 24px;
    border: none;
    background: transparent;
    font-family: "lato", sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 23px;
    text-align: center;
    color: #c2915e; }

#cart .product-line-grid .product-price {
  font-family: "lato", sans-serif;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #001253; }

#cart .product-line-grid .stock-product .in_stock {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #c2915e;
  margin-bottom: 0;
  background: #f8f2ea;
  border-radius: 15px;
  padding: 0 10px;
  display: inline-block; }

#cart .product-line-grid .stock-product .out_of_stock {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
  background: #001253;
  border-radius: 15px;
  padding: 0 10px;
  display: inline-block; }

#cart .cart-item:not(:last-child) {
  border-bottom: 1px dashed #f1d5a2 !important; }

#cart .cart-grid-right .cart-summary {
  background: #FBF7F2;
  border-radius: 30px;
  padding: 15px 5px 25px; }
  #cart .cart-grid-right .cart-summary .cart-summary-line.cart-total {
    font-family: "lato", sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #001253; }
  #cart .cart-grid-right .cart-summary .btn-primary {
    font-family: "lato", sans-serif;
    font-weight: 900;
    font-size: 16px;
    letter-spacing: .05em;
    line-height: 23px;
    color: #fff;
    text-transform: uppercase;
    padding: 11px 15px;
    border-radius: 30px;
    box-shadow: none;
    border: 1px solid #c2915e;
    background: #c2915e; }

@media (min-width: 992px) {
  body.layout-full-width .product-miniature {
    flex: 0 0 20%;
    max-width: 20%; } }

.product-miniature .card-body, .product-miniature .page-content, .product-miniature .block-categories, .product-miniature .address-body {
  padding: 20px 0; }

.product-miniature .card-product {
  background: transparent; }
  .product-miniature .card-product .card-img-top {
    border-radius: 10px;
    border: 1px solid #f1d5a2; }
  .product-miniature .card-product .product-title a {
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #001253;
    text-transform: capitalize; }
  .product-miniature .card-product .manufacturer {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #001253;
    text-transform: uppercase; }
  .product-miniature .card-product .add-to-cart {
    font-size: 0;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background: #C2915E;
    border-color: #C2915E;
    box-shadow: none;
    background-image: url("../img/cta4.svg");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center; }
  .product-miniature .card-product .container_add_to_cart {
    display: flex;
    justify-content: space-between; }
    .product-miniature .card-product .container_add_to_cart > div {
      width: 50%; }
      .product-miniature .card-product .container_add_to_cart > div.product-list-actions {
        display: flex;
        justify-content: center; }
    .product-miniature .card-product .container_add_to_cart .price {
      font-weight: bold;
      font-size: 18px;
      text-align: left;
      color: #001253;
      line-height: 18px; }
    .product-miniature .card-product .container_add_to_cart .col-price {
      display: flex;
      justify-content: center; }
      .product-miniature .card-product .container_add_to_cart .col-price .price_stock {
        display: inline-block; }
        .product-miniature .card-product .container_add_to_cart .col-price .price_stock .product-price-and-shipping {
          line-height: 22px; }
      .product-miniature .card-product .container_add_to_cart .col-price .stock-product {
        margin-top: 2px; }
        .product-miniature .card-product .container_add_to_cart .col-price .stock-product .in_stock {
          font-weight: 600;
          font-size: 12px;
          text-align: center;
          color: #c2915e;
          margin-bottom: 0;
          background: #F8F2EA;
          border-radius: 15px;
          padding: 0 5px;
          margin-left: -5px;
          line-height: 1.4; }
        .product-miniature .card-product .container_add_to_cart .col-price .stock-product .out_of_stock {
          font-weight: 600;
          font-size: 12px;
          text-align: center;
          color: #fff;
          margin-bottom: 0;
          background: #001253;
          border-radius: 15px;
          padding: 0 10px;
          margin-left: -5px;
          white-space: nowrap;
          line-height: 1.4; }

.PM_ASBlockOutputVertical .card-header, .PM_ASBlockOutputVertical .page-header, .PM_ASBlockOutputVertical .address-header, .PM_ASBlockOutput .card-header, .PM_ASBlockOutput .page-header, .PM_ASBlockOutput .address-header, .PM_ASBlockOutputHorizontal .card-header, .PM_ASBlockOutputHorizontal .page-header, .PM_ASBlockOutputHorizontal .address-header {
  display: none; }

.PM_ASBlockOutputVertical .PM_ASCritRange, .PM_ASBlockOutput .PM_ASCritRange, .PM_ASBlockOutputHorizontal .PM_ASCritRange {
  border: none;
  background: #F8F2EA;
  border-radius: 20px;
  margin-top: 25px; }
  .PM_ASBlockOutputVertical .PM_ASCritRange .ui-slider-range, .PM_ASBlockOutput .PM_ASCritRange .ui-slider-range, .PM_ASBlockOutputHorizontal .PM_ASCritRange .ui-slider-range {
    background: #DCC1A3; }
  .PM_ASBlockOutputVertical .PM_ASCritRange .ui-slider-handle, .PM_ASBlockOutput .PM_ASCritRange .ui-slider-handle, .PM_ASBlockOutputHorizontal .PM_ASCritRange .ui-slider-handle {
    background: #C2915E;
    border: none;
    border-radius: 50%;
    top: -3px; }

.PM_ASBlockOutputVertical .PM_ASCriterionsGroupTitle, .PM_ASBlockOutput .PM_ASCriterionsGroupTitle, .PM_ASBlockOutputHorizontal .PM_ASCriterionsGroupTitle {
  background: #FBF7F2;
  padding: 7px 12px !important;
  border: none !important;
  border-radius: 5px;
  position: relative;
  cursor: pointer; }
  .PM_ASBlockOutputVertical .PM_ASCriterionsGroupTitle:before, .PM_ASBlockOutput .PM_ASCriterionsGroupTitle:before, .PM_ASBlockOutputHorizontal .PM_ASCriterionsGroupTitle:before {
    content: "";
    width: 20px;
    height: 20px;
    background-image: url("../img/np_star_filter.svg");
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition-duration: 0.2s; }
  .PM_ASBlockOutputVertical .PM_ASCriterionsGroupTitle.active:before, .PM_ASBlockOutput .PM_ASCriterionsGroupTitle.active:before, .PM_ASBlockOutputHorizontal .PM_ASCriterionsGroupTitle.active:before {
    transform: translateY(-50%) rotate(45deg);
    transition-duration: 0.2s; }
  .PM_ASBlockOutputVertical .PM_ASCriterionsGroupTitle img, .PM_ASBlockOutput .PM_ASCriterionsGroupTitle img, .PM_ASBlockOutputHorizontal .PM_ASCriterionsGroupTitle img {
    margin-right: 8px; }
  .PM_ASBlockOutputVertical .PM_ASCriterionsGroupTitle .PM_ASCriterionsGroupName, .PM_ASBlockOutput .PM_ASCriterionsGroupTitle .PM_ASCriterionsGroupName, .PM_ASBlockOutputHorizontal .PM_ASCriterionsGroupTitle .PM_ASCriterionsGroupName {
    font-family: "lato", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: #001253; }

.PM_ASBlockOutputVertical .PM_ASCriterionGroupCheckbox, .PM_ASBlockOutput .PM_ASCriterionGroupCheckbox, .PM_ASBlockOutputHorizontal .PM_ASCriterionGroupCheckbox {
  padding-left: 5px; }
  .PM_ASBlockOutputVertical .PM_ASCriterionGroupCheckbox input, .PM_ASBlockOutput .PM_ASCriterionGroupCheckbox input, .PM_ASBlockOutputHorizontal .PM_ASCriterionGroupCheckbox input {
    display: none; }
  .PM_ASBlockOutputVertical .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox, .PM_ASBlockOutput .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox, .PM_ASBlockOutputHorizontal .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox {
    position: relative;
    padding-left: 25px; }
    .PM_ASBlockOutputVertical .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox:before, .PM_ASBlockOutput .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox:before, .PM_ASBlockOutputHorizontal .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox:before {
      content: "";
      width: 11px;
      height: 11px;
      position: absolute;
      left: 5px;
      top: 8px;
      border: 2px solid #001253;
      border-radius: 50%; }
    .PM_ASBlockOutputVertical .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox.PM_ASLabelCheckboxSelected:before, .PM_ASBlockOutput .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox.PM_ASLabelCheckboxSelected:before, .PM_ASBlockOutputHorizontal .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox.PM_ASLabelCheckboxSelected:before {
      background: #f0d4a2; }
    .PM_ASBlockOutputVertical .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox .PM_ASLabelLink, .PM_ASBlockOutput .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox .PM_ASLabelLink, .PM_ASBlockOutputHorizontal .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox .PM_ASLabelLink {
      font-family: "lato", sans-serif;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #001253; }
    .PM_ASBlockOutputVertical .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox .PM_ASCriterionNbProduct, .PM_ASBlockOutput .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox .PM_ASCriterionNbProduct, .PM_ASBlockOutputHorizontal .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox .PM_ASCriterionNbProduct {
      display: none; }

.block-category .card-body, .block-category .page-content, .block-category .block-categories, .block-category .address-body {
  background: #FBF7F2;
  padding: 0;
  margin: 40px 0; }

.block-category .category-cover {
  position: relative; }
  .block-category .category-cover:before {
    content: "";
    height: 90%;
    width: 100px;
    background-image: url("../img/after-img-cat.svg");
    position: absolute;
    right: 0;
    top: 5%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right; }
  .block-category .category-cover img {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    max-height: 380px;
    border-radius: 0 300px 300px 0;
    margin-left: -15px; }

.block-category .col-cat-desc {
  display: flex;
  align-items: center; }
  .block-category .col-cat-desc > div {
    max-width: 475px;
    margin: 0 auto; }
    .block-category .col-cat-desc > div .breadcrumb {
      margin-bottom: 0;
      padding: 0;
      font-family: "lato", sans-serif;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.05em;
      line-height: 23px;
      color: #001253; }
      .block-category .col-cat-desc > div .breadcrumb .breadcrumb-item.active span {
        color: #001253;
        font-weight: bold; }
    .block-category .col-cat-desc > div h1 {
      font-family: "cormorant-garamond", serif;
      font-weight: normal;
      font-size: 45px;
      letter-spacing: 0.07em;
      color: #001253;
      position: relative;
      margin-bottom: 30px;
      display: inline-block; }
      .block-category .col-cat-desc > div h1:before {
        content: "";
        width: 98%;
        height: 5px;
        background-image: url(../img/border-title.svg);
        position: absolute;
        bottom: -2px;
        background-size: cover; }
    .block-category .col-cat-desc > div #category-description {
      font-family: "lato", sans-serif;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.05em;
      line-height: 23px;
      text-align: left;
      color: #000; }

#select-sort-order {
  position: absolute;
  right: 0;
  opacity: 0; }

#js-product-list-top .product__quantity {
  font-family: "lato", sans-serif;
  font-weight: normal;
  font-style: italic;
  font-size: 14px;
  line-height: 23px;
  color: #001253; }

.pagination, .product-comments__pagination ul {
  margin: 30px 0 !important; }
  .pagination .page-item a, .product-comments__pagination ul .page-item a, .pagination .product-comments__pagination li a, .product-comments__pagination .pagination li a, .product-comments__pagination ul li a {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 0;
    font-family: "lato", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    color: #001253;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    border: none; }
  .pagination .page-item.next, .product-comments__pagination ul .page-item.next, .pagination .product-comments__pagination li.next, .product-comments__pagination .pagination li.next, .product-comments__pagination ul li.next {
    position: absolute;
    right: 0; }
    .pagination .page-item.next a, .product-comments__pagination ul .page-item.next a, .pagination .product-comments__pagination li.next a, .product-comments__pagination .pagination li.next a, .product-comments__pagination ul li.next a {
      width: auto;
      height: auto; }
      .pagination .page-item.next a:hover, .product-comments__pagination ul .page-item.next a:hover, .pagination .product-comments__pagination li.next a:hover, .product-comments__pagination .pagination li.next a:hover, .product-comments__pagination ul li.next a:hover {
        background-color: transparent; }
    .pagination .page-item.next .arrow-ek, .product-comments__pagination ul .page-item.next .arrow-ek, .pagination .product-comments__pagination li.next .arrow-ek, .product-comments__pagination .pagination li.next .arrow-ek, .product-comments__pagination ul li.next .arrow-ek {
      width: 33px;
      height: 33px;
      background: #001253;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      margin-left: 15px; }
  .pagination .page-item.prev, .product-comments__pagination ul .page-item.prev, .pagination .product-comments__pagination li.prev, .product-comments__pagination .pagination li.prev, .product-comments__pagination ul li.prev {
    position: absolute;
    left: 0; }
    .pagination .page-item.prev a, .product-comments__pagination ul .page-item.prev a, .pagination .product-comments__pagination li.prev a, .product-comments__pagination .pagination li.prev a, .product-comments__pagination ul li.prev a {
      width: auto;
      height: auto; }
      .pagination .page-item.prev a:hover, .product-comments__pagination ul .page-item.prev a:hover, .pagination .product-comments__pagination li.prev a:hover, .product-comments__pagination .pagination li.prev a:hover, .product-comments__pagination ul li.prev a:hover {
        background-color: transparent; }
    .pagination .page-item.prev .arrow-ek, .product-comments__pagination ul .page-item.prev .arrow-ek, .pagination .product-comments__pagination li.prev .arrow-ek, .product-comments__pagination .pagination li.prev .arrow-ek, .product-comments__pagination ul li.prev .arrow-ek {
      width: 33px;
      height: 33px;
      background: #001253;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      margin-right: 15px;
      transform: rotate(180deg); }
  .pagination .page-item.disabled span, .product-comments__pagination ul .page-item.disabled span, .pagination .product-comments__pagination li.disabled span, .product-comments__pagination .pagination li.disabled span, .product-comments__pagination ul li.disabled span {
    border: none;
    width: 25px;
    height: 25px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #001354; }

.breadcrumb-item + .breadcrumb-item:before {
  background-image: url("../img/pseudo_nav.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 8px;
  font-size: 0; }

@media (min-width: 992px) {
  #category .product-miniature {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 8px; } }

@media (max-width: 991px) {
  #header.l-header {
    margin-bottom: 25px !important; } }

@media (max-width: 767px) {
  .sort-by-row {
    justify-content: center !important;
    margin-bottom: 15px; } }

.block-category .category-cover {
  height: 100%;
  display: flex;
  align-items: center; }

#js-product-list {
  padding-bottom: 100px; }

.ekypia_slider_home img {
  height: 370px !important;
  width: 100%;
  object-fit: cover;
  position: relative; }

.ekypia_slider-container {
  margin-bottom: 0 !important;
  z-index: 2; }

#index .ekypia_slider_home {
  width: 2000px;
  max-width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%); }
  #index .ekypia_slider_home .slick-list {
    height: 370px !important; }
  #index .ekypia_slider_home .slide:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.65) 31.53%, transparent);
    opacity: .3;
    z-index: 1; }
  #index .ekypia_slider_home .caption.position_0 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2; }
    #index .ekypia_slider_home .caption.position_0 .content {
      padding: 50px 60px; }
  #index .ekypia_slider_home .caption .content .titre {
    font-family: "cormorant-garamond", serif;
    font-weight: 600;
    font-size: 35px;
    letter-spacing: 0.07em;
    line-height: 35px;
    color: #fff;
    max-width: 280px;
    display: block;
    margin-bottom: 20px; }
  #index .ekypia_slider_home .caption .content .btn {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.05em;
    line-height: 23px;
    color: #001253;
    text-transform: uppercase;
    background: #FBF7F2;
    border-color: #FBF7F2;
    border-radius: 40px;
    padding: 7px 20px;
    transition-duration: 0.2s; }
    #index .ekypia_slider_home .caption .content .btn:before, #index .ekypia_slider_home .caption .content .btn:after {
      content: "";
      width: 0px;
      height: 0px;
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../img/pseudo_nav.svg");
      transition-duration: 0.2s; }
    #index .ekypia_slider_home .caption .content .btn:hover {
      transform: scale(1.05);
      transition-duration: 0.2s; }
      #index .ekypia_slider_home .caption .content .btn:hover:before, #index .ekypia_slider_home .caption .content .btn:hover:after {
        content: "";
        width: 10px;
        height: 10px;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../img/pseudo_nav.svg");
        transition-duration: 0.2s; }
      #index .ekypia_slider_home .caption .content .btn:hover:before {
        margin-right: 10px; }
      #index .ekypia_slider_home .caption .content .btn:hover:after {
        margin-left: 10px; }

.featured-products .products-section-title {
  font-family: "cormorant-garamond", serif;
  font-weight: normal;
  font-size: 45px;
  letter-spacing: 0.07em;
  color: #c2915e;
  line-height: 45px;
  max-width: 470px;
  margin: 0 auto;
  margin-bottom: 60px;
  text-transform: none;
  text-align: left; }
  .featured-products .products-section-title span {
    font-weight: normal;
    font-size: 45px;
    letter-spacing: 0.07em;
    color: #001253;
    display: block;
    line-height: 45px;
    text-align: right; }
    .featured-products .products-section-title span span {
      display: inline-block;
      position: relative; }
      .featured-products .products-section-title span span:before {
        content: "";
        width: 98%;
        height: 5px;
        background-image: url("../img/border-title.svg");
        position: absolute;
        bottom: -2px;
        background-size: cover; }

.featured-products.new-products {
  position: relative;
  padding-top: 20px; }
  .featured-products.new-products .paralax-left {
    position: absolute;
    left: -30px;
    top: -100px; }
  .featured-products.new-products .paralax-right {
    position: absolute;
    right: -200px;
    top: -40px; }

.fake-rea .elementor-widget-heading {
  margin-bottom: 0 !important; }

.fake-rea h2.elementor-heading-title {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15em;
  text-align: center;
  color: #c2915e;
  text-transform: uppercase; }

.fake-rea span.elementor-heading-title {
  font-family: "cormorant-garamond", serif;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #001253; }

.fake-rea .elementor-image {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .fake-rea .elementor-image img {
    max-height: 100%; }

.fake-rea .title-rea .elementor-heading-title {
  font-family: "cormorant-garamond", serif;
  font-weight: normal;
  font-size: 45px;
  letter-spacing: 0.07em;
  color: #001253;
  line-height: 45px;
  max-width: 470px;
  margin: 0 auto;
  margin-bottom: 60px;
  text-transform: none;
  text-align: left; }
  .fake-rea .title-rea .elementor-heading-title span {
    font-weight: normal;
    font-size: 45px;
    letter-spacing: 0.07em;
    color: #c2915e;
    display: block;
    line-height: 45px;
    text-align: right; }
    .fake-rea .title-rea .elementor-heading-title span span {
      display: inline-block;
      position: relative; }
      .fake-rea .title-rea .elementor-heading-title span span:before {
        content: "";
        width: 98%;
        height: 5px;
        background-image: url("../img/border_title_blue.svg");
        position: absolute;
        bottom: -8px;
        background-size: cover; }

.fake-rea.bottom:before {
  content: "";
  width: 100%;
  height: 90px;
  background-image: url("../img/etoile-bleu.svg");
  position: absolute;
  top: 0;
  max-width: 1080px;
  left: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  transform: translateX(-50%); }

.fake-rea.bottom:after {
  content: "";
  width: 100%;
  height: 90px;
  background-image: url("../img/etoile-doree.svg");
  position: absolute;
  bottom: 0;
  max-width: 1080px;
  left: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  transform: translateX(-50%); }

#background-round {
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 40px; }
  #background-round:before {
    content: "";
    width: 100%;
    padding-top: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    background: #FBF7F2;
    border-radius: 50%;
    transform: translate(-50%, -50%); }
  #background-round .elementor-widget-heading {
    margin-bottom: 0 !important; }
  #background-round h2.elementor-heading-title {
    font-family: "cormorant-garamond", serif;
    font-weight: normal;
    font-size: 45px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #c2915e; }
  #background-round span.elementor-heading-title {
    font-family: "cormorant-garamond", serif;
    font-weight: normal;
    font-size: 45px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #001253;
    position: relative;
    margin-bottom: 30px;
    display: inline-block; }
    #background-round span.elementor-heading-title:after {
      content: "";
      width: 98%;
      height: 4px;
      background-image: url(../img/border-title.svg);
      position: absolute;
      bottom: -2px;
      background-size: cover;
      left: 0; }
  #background-round .elementor-text-editor {
    font-family: "lato", sans-serif;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 23px;
    text-align: center;
    color: #001253;
    margin: 0 auto;
    max-width: 450px; }
  #background-round .elementor-button-link {
    background-color: #C2915E !important;
    border-radius: 30px; }
    #background-round .elementor-button-link span {
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.05em;
      line-height: 14px;
      text-align: center;
      color: #fbf7f2;
      text-transform: uppercase; }

.elementor-cta--skin-cover .elementor-cta-content {
  padding: 20px;
  text-align: left;
  flex-direction: column;
  padding-top: 65px;
  min-height: 250px !important; }
  .elementor-cta--skin-cover .elementor-cta-content:before {
    content: "";
    height: 180px;
    width: 100%;
    background-image: url("../img/triangle.svg");
    position: absolute;
    left: 0;
    background-size: contain;
    top: 0;
    background-repeat: no-repeat; }
  .elementor-cta--skin-cover .elementor-cta-content .elementor-cta-button-wrapper {
    position: absolute;
    bottom: 20px;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    text-align: right;
    padding-right: 20px; }
    .elementor-cta--skin-cover .elementor-cta-content .elementor-cta-button-wrapper .elementor-button {
      font-weight: 700;
      font-size: 14px;
      letter-spacing: .05em;
      line-height: 14px;
      color: #001253;
      text-transform: uppercase;
      background: #fbf7f2;
      border-color: #fbf7f2;
      border-radius: 40px;
      padding: 7px 20px; }
  .elementor-cta--skin-cover .elementor-cta-content .elementor-cta-title {
    line-height: 25px;
    margin-bottom: 0 !important;
    text-align: left;
    font-family: "cormorant-garamond", serif;
    font-weight: 500;
    font-size: 35px;
    letter-spacing: 0.02em;
    color: #f1d5a2; }
  .elementor-cta--skin-cover .elementor-cta-content .elementor-cta-description {
    line-height: 25px;
    margin-bottom: 0 !important;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .15em;
    color: #fff;
    text-transform: uppercase; }

.col-histoire {
  padding: 30px;
  background: #fff;
  padding-top: 80px;
  border-top-left-radius: 280px;
  border-top-right-radius: 280px;
  position: relative; }
  .col-histoire:before {
    content: "";
    width: 150px;
    height: 100px;
    position: absolute;
    background-image: url("../img/before-histoire.svg");
    left: -25px;
    top: 0;
    z-index: 0;
    background-size: contain;
    background-repeat: no-repeat; }
  .col-histoire .elementor-image img {
    max-width: 400px; }
  .col-histoire .elementor-text-editor {
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 23px;
    text-align: center;
    color: #000; }
  .col-histoire .elementor-button-link {
    background-color: #c2915e !important;
    border-radius: 30px; }
    .col-histoire .elementor-button-link span {
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.05em;
      text-align: center;
      color: #fff;
      line-height: 14px;
      text-transform: uppercase; }
  .col-histoire .elementor-widget-heading {
    margin-bottom: 0 !important; }
  .col-histoire h2.elementor-heading-title {
    font-family: "cormorant-garamond", serif;
    font-weight: normal;
    font-size: 45px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #001253; }
  .col-histoire span.elementor-heading-title {
    font-family: "cormorant-garamond", serif;
    font-weight: normal;
    font-size: 45px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #C2915E;
    position: relative;
    margin-bottom: 30px;
    display: inline-block; }
    .col-histoire span.elementor-heading-title:after {
      content: "";
      width: 98%;
      height: 4px;
      background-image: url(../img/border_title_blue.svg);
      position: absolute;
      bottom: -2px;
      background-size: cover;
      left: 0; }

#last-post {
  max-width: 400px;
  margin-right: 0;
  margin-left: auto;
  position: relative; }
  #last-post:before {
    content: "";
    width: 110px;
    height: 50px;
    position: absolute;
    background-image: url(../img/before-first-actu.svg);
    right: -30px;
    top: 0;
    z-index: 0;
    background-size: contain;
    background-repeat: no-repeat; }
  #last-post h2.title {
    font-family: "cormorant-garamond", serif;
    font-weight: 400;
    font-size: 45px;
    letter-spacing: .07em;
    text-align: right;
    color: #fff;
    text-transform: initial !important; }
    #last-post h2.title span {
      color: #c2915e;
      position: relative;
      margin-bottom: 30px;
      display: inline-block; }
      #last-post h2.title span:before {
        content: "";
        width: 98%;
        height: 4px;
        background-image: url(../img/border-title-white.svg);
        position: absolute;
        bottom: -2px;
        background-size: cover;
        left: 0; }
  #last-post .blog-grid .block_top img {
    width: 100%;
    max-height: 270px;
    object-fit: cover;
    border-bottom-left-radius: 150px;
    border-top-left-radius: 150px; }
  #last-post .blog-grid .block_bas {
    margin-top: 20px; }
    #last-post .blog-grid .block_bas h3 a, #last-post .blog-grid .block_bas h3 {
      font-family: "cormorant-garamond", serif;
      font-weight: normal;
      font-size: 28px;
      letter-spacing: 0.04em;
      line-height: 30px;
      text-align: right;
      color: #fff; }
    #last-post .blog-grid .block_bas .blog_desc {
      font-family: "lato", sans-serif;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.05em;
      line-height: 23px;
      text-align: right;
      color: #fff;
      margin: 25px 0; }
  #last-post .blog-grid .prestablog_more {
    text-align: right; }
    #last-post .blog-grid .prestablog_more a {
      font-family: "lato", sans-serif;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.08em;
      line-height: 23px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      #last-post .blog-grid .prestablog_more a svg {
        margin-left: 10px; }

body, html {
  overflow-x: hidden; }

#placeReviews {
  position: relative; }

.google-reviews-section-card {
  position: absolute !important;
  height: auto;
  width: 100%;
  left: 33.333%;
  top: 50%;
  transform: translateY(-50%); }

.bgReviews {
  height: auto !important;
  padding: 15px 10px; }
  .bgReviews .placereviews_flexrow .nameReviews {
    width: 100%;
    margin: 0; }
  .bgReviews .paddingText {
    padding: 0; }

.content-review {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  padding: 15px 20px 25px;
  height: 100%; }
  .content-review .csoft-author-name {
    font-family: "lato", sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 23px;
    color: #001253; }
  .content-review .wp-star path {
    fill: #C2915E; }
  .content-review .csoft-time-description {
    font-family: "lato", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #c2915e; }
  .content-review .paddingText {
    font-family: "lato", sans-serif;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 23px;
    color: #001253; }

#placeReviews {
  margin-top: -32px;
  padding: 60px 0 !important; }
  #placeReviews .google-reviews-section-title {
    font-family: "cormorant-garamond", serif;
    font-weight: normal;
    font-size: 30px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #c2915e;
    text-transform: initial;
    margin-top: 0; }
  #placeReviews .google-reviews-section-info {
    margin-top: 0;
    margin-bottom: 0; }
    #placeReviews .google-reviews-section-info > p {
      font-family: "cormorant-garamond", serif;
      font-weight: bold;
      font-size: 30px;
      text-align: center;
      color: #001253;
      margin-bottom: 0; }
  #placeReviews .reviews-count {
    font-family: "lato", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #c2915e; }
  #placeReviews .carre {
    width: 270px;
    height: 270px;
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: solid 8px #F8F0E8; }
    #placeReviews .carre:before {
      content: "";
      width: 8px;
      height: 65px;
      background: #f8f0e8;
      position: absolute;
      left: 50%;
      top: -68px;
      transform: translateX(-50%); }
  #placeReviews .csoft-ratecolor {
    font-family: "lato", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #001253; }
  #placeReviews .slick-prev {
    position: absolute;
    left: -11%;
    font-size: 0;
    width: 33px;
    height: 33px;
    background: #C2915E;
    border: none;
    border-radius: 5px;
    bottom: 0;
    background-image: url("../img/np_arrow_5732592_000000.svg");
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
    transform: translateX(-50%) rotate(180deg); }
  #placeReviews .slick-next {
    position: absolute;
    left: calc(-11% + 45px);
    font-size: 0;
    width: 33px;
    height: 33px;
    background: #C2915E;
    border: none;
    border-radius: 5px;
    bottom: 0;
    background-image: url("../img/np_arrow_5732592_000000.svg");
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center; }
  #placeReviews .google-reviews-section-info .wp-stars .wp-star svg {
    width: 20px;
    height: 20px; }
    #placeReviews .google-reviews-section-info .wp-stars .wp-star svg path {
      fill: #C2915E; }

.position-initial {
  position: initial; }

@media (max-width: 1150px) {
  .featured-products.new-products .paralax-right,
  .featured-products.new-products .paralax-left {
    display: none; }
  .col-histoire .elementor-image img {
    max-width: 100%;
    width: 400px; } }

@media (max-width: 991px) {
  .google-reviews-section-card {
    position: initial !important;
    left: 0;
    transform: none;
    margin-top: 30px; }
  .featured-products .slick-next, .featured-products .slick-prev {
    bottom: -20px !important; } }

@media (max-width: 767px) {
  .fake-rea.bottom:after, .fake-rea.bottom:before {
    display: none; }
  .col-histoire {
    padding: 80px 15px 30px;
    margin-bottom: 40px; }
  #index .ekypia_slider_home .caption .content .titre {
    margin: 0 auto 20px; }
  #index .ekypia_slider_home .caption.position_0 .content {
    padding: 50px 15px;
    text-align: center; }
  #index .ekypia_slider_home .caption.position_0 {
    width: 100%; } }

#contact .js-customer-form {
  max-width: 700px; }

#login-form, .forgotten-password, #customer-form, #contact .js-customer-form {
  padding: 15px;
  background: #fbf7f2;
  border-radius: 8px;
  color: #001253;
  margin-bottom: 60px; }
  #login-form .btn[data-action="show-password"], .forgotten-password .btn[data-action="show-password"], #customer-form .btn[data-action="show-password"], #contact .js-customer-form .btn[data-action="show-password"] {
    background: #c2915e;
    border-color: #c2915e;
    color: #fff;
    box-shadow: none; }
  #login-form .form-footer, .forgotten-password .form-footer, #customer-form .form-footer, #contact .js-customer-form .form-footer {
    text-align: center; }
  #login-form #submit-login, .forgotten-password #submit-login, #customer-form #submit-login, #contact .js-customer-form #submit-login {
    background: #001253;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    padding: 5px 30px; }

#authentication h1, #password h1, #my-account h1, #identity h1, #addresses h1, #module-blockwishlist-lists h1, #view h1 {
  color: #001253;
  text-align: center; }

#authentication #footer, #password #footer, #my-account #footer, #identity #footer, #addresses #footer, #module-blockwishlist-lists #footer, #view #footer {
  margin-top: 60px; }

.account-links .link-item {
  border-radius: 10px;
  border: 1px solid #C2915E;
  background: #C2915E;
  color: #fff;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  height: 100%;
  min-height: 180px;
  align-items: center;
  justify-content: center; }

.wishlist-modal + .modal-backdrop.in {
  display: none; }

.wishlist-list li {
  background: #fbf7f2;
  border: none !important;
  border-radius: 5px;
  margin-bottom: 17px; }

.wishlist-modal:not(.show) {
  display: none !important; }

.wishlist-products-container-header {
  flex-direction: column; }
  .wishlist-products-container-header .sort-by-row {
    display: none !important; }

.wishlist-product .wishlist-product-image {
  border-radius: 10px;
  border: 1px solid #f1d5a2; }

.wishlist-product .wishlist-product-title {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: .07em;
  text-align: center;
  color: #001253;
  text-transform: uppercase; }

.wishlist-product .wishlist-product-addtocart {
  height: 40px;
  border-radius: 5px;
  background: #c2915e;
  border-color: #c2915e;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  font-weight: bold; }
  .wishlist-product .wishlist-product-addtocart i {
    display: none; }

.wishlist-product .wishlist-product-price {
  font-weight: 700;
  font-size: 18px;
  text-align: left;
  color: #001253;
  line-height: 18px; }

#cms h1 {
  font-family: "cormorant-garamond", serif;
  font-weight: normal;
  font-size: 45px;
  letter-spacing: 0.07em;
  text-align: center;
  color: #001253; }
  #cms h1 span {
    display: block; }
    #cms h1 span span {
      font-family: "cormorant-garamond", serif;
      font-weight: normal;
      font-size: 45px;
      letter-spacing: 0.07em;
      text-align: center;
      color: #c2915e;
      position: relative;
      display: inline-block; }
      #cms h1 span span:after {
        content: "";
        width: 98%;
        height: 4px;
        background-image: url(../img/border_title_blue.svg);
        position: absolute;
        bottom: -2px;
        background-size: cover;
        left: 0; }

#cms .elementor-text-editor {
  font-family: "lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 23px;
  color: #000; }

#cms .text-white .elementor-text-editor {
  color: #fff;
  max-width: 500px;
  margin: 0 auto; }

#cms .elementor-button-link {
  background-color: #c2915e !important;
  border-radius: 30px; }
  #cms .elementor-button-link span {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: .05em;
    line-height: 14px;
    text-align: center;
    color: #fbf7f2;
    text-transform: uppercase; }

#cms #noel {
  margin-top: 100px;
  border: solid 1px #C2915E;
  border-radius: 20px;
  margin-bottom: 60px;
  padding-top: 40px;
  padding-bottom: 25px; }
  #cms #noel .elementor-text-editor {
    width: 560px;
    max-width: 100%;
    margin: 0 auto; }
  #cms #noel .elementor-widget-image {
    left: -30px;
    position: absolute;
    height: calc(100% + 205px);
    bottom: -75px;
    width: calc(100% + 30px);
    background: linear-gradient(to left, transparent 50%, #fff 50%); }
    #cms #noel .elementor-widget-image .elementor-widget-container {
      height: 100%;
      position: relative; }
      #cms #noel .elementor-widget-image .elementor-widget-container:before {
        content: "";
        width: 110px;
        height: 110px;
        background-image: url("../img/before-img-noel.svg");
        position: absolute;
        left: -20px;
        top: -40px;
        background-size: contain;
        background-repeat: no-repeat; }
      #cms #noel .elementor-widget-image .elementor-widget-container .elementor-image {
        height: 100%;
        position: relative; }
        #cms #noel .elementor-widget-image .elementor-widget-container .elementor-image img {
          height: 100%;
          object-fit: cover;
          border-radius: 300px 300px 0 0; }

#cms .text-noel {
  width: auto;
  margin: 0 auto 20px auto;
  position: relative; }
  #cms .text-noel:before {
    content: "";
    width: 115px;
    height: 165px;
    background-image: url("../img/text-noel-left.svg");
    position: absolute;
    right: -140px;
    bottom: -80px;
    background-repeat: no-repeat; }
  #cms .text-noel:after {
    content: "";
    width: 115px;
    height: 165px;
    background-image: url("../img/text-noel-right.svg");
    position: absolute;
    left: -140px;
    top: -25px; }

#cms .text-boutique {
  position: relative; }
  #cms .text-boutique:before {
    content: "";
    background-image: url("../img/after-text-boutique.svg");
    right: -240px;
    bottom: 50%;
    background-repeat: no-repeat;
    width: 200px;
    height: 260px;
    background-size: contain;
    transform: translateY(50%);
    position: absolute; }
  #cms .text-boutique:after {
    content: "";
    background-image: url("../img/before-text-boutique.svg");
    left: -240px;
    bottom: 50%;
    background-repeat: no-repeat;
    width: 200px;
    height: 260px;
    background-size: contain;
    transform: translateY(50%);
    position: absolute; }

#cms .text-top-boutique {
  padding: 80px 15px;
  text-align: center;
  margin-top: 40px;
  position: relative; }
  #cms .text-top-boutique:before {
    content: "";
    background: #f8f2ea;
    border-radius: 20px;
    position: absolute;
    left: -60px;
    width: calc(200% + 120px);
    height: 100%;
    top: 0; }
  #cms .text-top-boutique .elementor-widget-container {
    position: relative; }

#cms .img-top-boutique img {
  border-radius: 300px 300px 0 0;
  max-width: 390px;
  margin: 0 auto;
  min-height: 550px;
  object-fit: cover; }

.elementor-cta--skin-cover .elementor-cta-content .elementor-button-text {
  color: #001253; }

.elementor-cta--skin-cover .elementor-cta-content .elementor-button:hover {
  background: #001253 !important;
  border-color: #001253 !important; }
  .elementor-cta--skin-cover .elementor-cta-content .elementor-button:hover .elementor-button-text {
    color: #fff; }

#content-wrapper .all-brands {
  margin-bottom: 100px; }

#js-checkout-summary {
  background: #fbf7f2;
  border-radius: 20px;
  margin-top: 50px; }

#js-checkout-process {
  margin-top: 50px; }
  #js-checkout-process .step-number {
    font-family: "lato", sans-serif;
    font-weight: 900;
    font-size: 30px;
    line-height: 24px;
    text-align: center;
    text-shadow: -1px 0 #c2915e, 0 1px #c2915e, 1px 0 #c2915e, 0 -1px #c2915e;
    color: #fff; }
  #js-checkout-process .step-title {
    font-family: "cormorant-garamond", serif;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    color: #001253;
    display: flex;
    align-items: center; }
  #js-checkout-process .js-checkout-step-header, #js-checkout-process .checkout-step .card-header, #js-checkout-process .checkout-step .page-header, #js-checkout-process .checkout-step .address-header {
    border-bottom: 1px dashed;
    border-color: #c3905f;
    padding-left: 0;
    padding-right: 0; }
    #js-checkout-process .js-checkout-step-header svg, #js-checkout-process .checkout-step .card-header svg, #js-checkout-process .checkout-step .page-header svg, #js-checkout-process .checkout-step .address-header svg {
      margin: 0 10px; }

#cms #content-wrapper, #contact #content-wrapper {
  margin-bottom: 100px; }

body #lgcookieslaw_banner.lgcookieslaw-banner-floating {
  display: table;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  background: #fff;
  top: initial;
  left: initial;
  right: 10px;
  bottom: 10px;
  transform: initial;
  border-radius: 20px;
  max-width: 500px; }

body #lgcookieslaw_banner .lgcookieslaw-banner-message p {
  color: #000 !important; }

body #lgcookieslaw_banner .lgcookieslaw-banner-message a {
  text-decoration: underline;
  color: #000 !important; }

body #lgcookieslaw_banner .lgcookieslaw-button-container {
  justify-content: flex-end; }
  body #lgcookieslaw_banner .lgcookieslaw-button-container .lgcookieslaw-reject-button {
    width: auto;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    background: #e6e6e6 !important;
    color: #222222 !important;
    font-weight: 600;
    text-transform: none;
    margin-right: 15px; }
  body #lgcookieslaw_banner .lgcookieslaw-button-container .lgcookieslaw-accept-button {
    width: auto;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    background: #c3905f !important;
    font-weight: 600;
    text-transform: none; }

.title-blue h2 {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: .07em;
  color: #001253;
  text-decoration: underline;
  margin-top: 20px; }

@media (max-width: 767px) {
  #cms #noel .elementor-widget-image {
    position: initial;
    width: 100%;
    height: 100%;
    max-width: 400px;
    margin: 0 auto; }
  .page-content {
    padding: 0; }
  #cms #content-wrapper {
    padding: 0; }
  #last-post {
    max-width: 100%; }
  #last-post:before {
    display: none; }
  #last-post h2.title {
    text-align: center; }
  #last-post .blog-grid .block_bas h3, #last-post .blog-grid .block_bas h3 a,
  #last-post .blog-grid .block_bas .blog_desc {
    text-align: center; }
  #last-post .blog-grid .prestablog_more a {
    justify-content: center; }
  #last-post .blog-grid .block_top img {
    border-radius: 0;
    max-height: 325px; }
  .col-histoire {
    margin-top: 40px; }
  #cms #noel {
    margin-top: 50px;
    margin-bottom: 0; }
  #cms .text-top-boutique {
    padding: 30px 15px;
    text-align: center;
    margin-top: 40px;
    position: relative;
    margin-bottom: 40px; }
  body .first-article .block_cont {
    padding: 15px;
    flex-direction: column;
    border-radius: 5px; }
    body .first-article .block_cont > svg {
      display: none; }
    body .first-article .block_cont .block_top {
      width: 100%;
      padding-right: 0;
      padding-bottom: 20px; }
    body .first-article .block_cont .block_bas {
      width: 100%; }
  #blockcart-modal .modal-footer .btn-outline-secondary {
    margin-bottom: 15px; } }

#checkout #header {
  min-height: auto; }

.step-number {
  font-weight: 700;
  color: #ced4da; }

.js-product-images-modal .slick-slider {
  touch-action: auto; }

.images-container {
  display: flex; }
  .images-container .position-relative {
    width: 80%;
    display: inline-block; }
  .images-container .product-thumbs {
    display: inline-block;
    height: 100%;
    padding: 0 20px; }

.product-add-to-cart .qty {
  margin-left: 0 !important; }

.product-add-to-cart .bootstrap-touchspin {
  border: none;
  background: #FBF7F2;
  border-radius: 30px;
  padding: 8px 12px; }
  .product-add-to-cart .bootstrap-touchspin .btn.js-touchspin {
    width: 24px;
    height: 24px;
    background: #C2915E;
    line-height: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    transition-duration: 0.2s; }
    .product-add-to-cart .bootstrap-touchspin .btn.js-touchspin:hover {
      transition-duration: 0.2s;
      transform: scale(1.08); }
  .product-add-to-cart .bootstrap-touchspin #quantity_wanted {
    height: 24px;
    border: none;
    background: transparent;
    font-family: "lato", sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 23px;
    text-align: center;
    color: #c2915e; }

.product-add-to-cart .btn-add-to-cart {
  font-family: "lato", sans-serif;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: .05em;
  line-height: 23px;
  color: #fff;
  text-transform: uppercase;
  padding: 7px 15px;
  border-radius: 30px;
  box-shadow: none;
  border: 1px solid #c2915e;
  background: #c2915e;
  display: flex; }
  .product-add-to-cart .btn-add-to-cart svg {
    margin-right: 10px;
    margin-top: -3px; }

.product-add-to-cart .wishlist-button-add {
  border: 1px dotted #001253;
  box-shadow: none;
  margin-top: 1rem;
  color: #001253; }
  .product-add-to-cart .wishlist-button-add i {
    font-weight: 300;
    color: #001253; }

#product h1 {
  font-family: "cormorant-garamond", serif;
  font-weight: 600;
  font-size: 35px;
  line-height: 45px;
  color: #001253; }

#product .product-prices .product-price {
  font-family: "lato", sans-serif;
  font-weight: 900;
  font-size: 28px;
  color: #001253; }

#product .product-actions .stock-product {
  display: inline-block;
  margin: 25px 0 15px 5px; }
  #product .product-actions .stock-product .in_stock {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #c2915e;
    margin-bottom: 0;
    background: #f8f2ea;
    border-radius: 15px;
    padding: 0 12px;
    line-height: 1.4; }
  #product .product-actions .stock-product .out_of_stock {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #fff;
    margin-bottom: 0;
    background: #001253;
    border-radius: 15px;
    padding: 0 12px;
    white-space: nowrap;
    line-height: 1.4; }

#product .sku-product {
  font-family: "lato", sans-serif;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.07em;
  text-align: left;
  color: #c2915e; }

#product .products-imagescover {
  border-radius: 10px;
  border: 1px solid #f1d5a2;
  overflow: hidden; }

#product .manufacturer-product {
  position: relative; }
  #product .manufacturer-product:before {
    content: "";
    width: 100%;
    height: 4px;
    background-image: url("../img/after-marque.svg");
    position: absolute;
    right: 50%;
    top: 55%;
    transform: translate(50%, -50%); }
  #product .manufacturer-product .product-manufacturer {
    display: inline-block;
    background: #fff;
    position: relative;
    z-index: 2;
    padding-right: 12px; }
  #product .manufacturer-product a {
    font-family: "lato", sans-serif;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.07em;
    color: #001253;
    text-transform: uppercase; }

#product #col-features h2 {
  font-family: "cormorant-garamond", serif;
  font-weight: normal;
  font-size: 35px;
  letter-spacing: 0.07em;
  color: #001253;
  position: relative;
  display: inline-block;
  margin-bottom: 30px; }
  #product #col-features h2:before {
    content: "";
    width: 98%;
    height: 4px;
    background-image: url(../img/after-marque.svg);
    position: absolute;
    bottom: -2px;
    background-size: cover; }

#product #col-features .col-feature {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }
  #product #col-features .col-feature .icon {
    margin-right: 10px;
    width: 25px; }

.product-flag.features {
  background: transparent;
  display: flex; }
  .product-flag.features img {
    width: 45px;
    height: 45px;
    margin: 0 5px; }

.product-miniature .product-flag.features img {
  width: 30px;
  height: 30px; }

.product-flags {
  padding-left: 15px; }
  .product-flags .product-flag:not(.features) {
    background: #001253;
    text-transform: uppercase;
    font-family: "lato", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    padding: 4px 8px;
    display: flex;
    align-items: center; }
    .product-flags .product-flag:not(.features) svg {
      margin: 0 5px; }

#conseil .content {
  max-width: 80%; }

#conseil h2 {
  font-family: "cormorant-garamond", serif;
  font-weight: normal;
  font-size: 35px;
  letter-spacing: 0.07em;
  text-align: center;
  color: #c2915e;
  padding-top: 40px;
  position: relative; }
  #conseil h2:before {
    content: "";
    width: 160px;
    height: 160px;
    background-image: url("../img/conseil.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0; }
  #conseil h2:after {
    content: "";
    width: 440px;
    height: 440px;
    background-image: url("../img/before_header.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    position: absolute;
    left: 0;
    transform: translate(-40%, -45%);
    top: 0;
    z-index: -1; }
  #conseil h2 span {
    font-family: "cormorant-garamond", serif;
    font-weight: normal;
    font-size: 35px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #001253;
    position: relative;
    margin-bottom: 30px;
    display: inline-block; }
    #conseil h2 span:after {
      content: "";
      width: 98%;
      height: 4px;
      background-image: url(../img/border-title.svg);
      position: absolute;
      bottom: -2px;
      background-size: cover;
      left: 0; }

#conseil .product-description-short {
  max-width: 400px;
  margin: 0 auto; }
  #conseil .product-description-short * {
    font-family: "lato", sans-serif !important;
    font-weight: 400;
    font-size: 16px !important;
    letter-spacing: .05em;
    line-height: 23px;
    text-align: center;
    color: #001253 !important;
    background-color: transparent !important; }

#same-cat-products {
  position: relative;
  z-index: 2;
  padding: 75px 0 120px;
  margin-top: 40px; }
  #same-cat-products:before {
    content: "";
    width: 100vw;
    height: 100%;
    background: #FBF7F2;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: -1; }
  #same-cat-products:after {
    content: "";
    width: 150px;
    height: 150px;
    background-size: contain;
    background-position: top center;
    background-image: url("../img/same-cat.svg");
    position: absolute;
    right: 0;
    top: 0; }
  #same-cat-products h2 {
    font-family: "cormorant-garamond", serif;
    font-weight: 400;
    font-size: 45px;
    letter-spacing: .07em;
    color: #c2915e;
    line-height: 45px;
    max-width: 365px;
    margin: 0 auto 60px;
    text-transform: none;
    text-align: left; }
    #same-cat-products h2 > span {
      font-weight: 400;
      font-size: 45px;
      letter-spacing: .07em;
      color: #001253;
      display: block;
      line-height: 45px;
      text-align: right; }
      #same-cat-products h2 > span > span {
        display: inline-block;
        position: relative; }
        #same-cat-products h2 > span > span:before {
          content: "";
          width: 98%;
          height: 5px;
          background-image: url(../img/border-title.svg);
          position: absolute;
          bottom: -2px;
          background-size: cover; }
  #same-cat-products .products {
    border-radius: 5px;
    background: #fff;
    padding: 15px 0; }

.wishlist-add-to .modal-backdrop {
  display: none !important; }

.featured-products .products {
  padding: 15px 0 !important; }

.featured-products .slick-dots {
  display: flex;
  justify-content: center; }
  .featured-products .slick-dots li {
    margin: 0 2px; }
    .featured-products .slick-dots li button {
      padding: 0;
      font-size: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #001354; }
    .featured-products .slick-dots li.slick-active button {
      background: #001354; }

.featured-products .slick-next {
  height: auto;
  bottom: 20px;
  top: auto;
  left: calc(50% + 60px);
  right: auto;
  transform: translateX(-50%); }

.featured-products .slick-prev {
  height: auto;
  bottom: 20px;
  top: auto;
  left: calc(50% - 60px);
  right: auto;
  transform: translateX(-50%); }

.featured-products .slick-arrow {
  box-shadow: none !important; }

#product-availability {
  font-family: "lato", sans-serif;
  font-weight: normal;
  font-style: italic;
  font-size: 14px;
  line-height: 23px;
  color: #001253;
  margin-top: 15px;
  display: inline-block; }

body:not(#category) .breadcrumb {
  padding-bottom: 10px;
  margin-bottom: 45px;
  border-bottom: 1px dashed #001253;
  font-family: "lato", sans-serif;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 23px;
  color: #001253; }
  body:not(#category) .breadcrumb .breadcrumb-item.active span {
    color: #001253;
    font-weight: bold; }

.images-container .product-thumbs {
  width: 20%; }

@media (min-width: 575px) {
  .images-container .product-thumbs .product-thumb {
    height: auto;
    margin: 0; }
    .images-container .product-thumbs .product-thumb img {
      border: 1px solid #F1D5A2;
      border-radius: 10px; }
    .images-container .product-thumbs .product-thumb .rc {
      background: #fff !important; } }

@media (max-width: 991px) {
  .images-container {
    margin-bottom: 40px; }
  #conseil h2:after {
    display: none; }
  #conseil .content {
    max-width: 100%;
    margin: 0 auto; }
  #col-features {
    text-align: center; }
  #product #col-features .col-feature {
    justify-content: center; } }

@media (max-width: 574px) {
  #conseil h2:before {
    display: none; }
  .images-container .product-thumbs {
    width: 100%; }
  .images-container .position-relative {
    width: 100%; }
  .images-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px; }
  .images-container .product-thumbs .product-thumb img {
    border: 1px solid #f1d5a2;
    border-radius: 10px; } }

@media (max-width: 767px) {
  #same-cat-products:after {
    display: none; } }

.first-article .block_cont {
  background: #001253;
  padding: 50px 80px;
  border-bottom-right-radius: 500px;
  border-top-right-radius: 500px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  position: relative; }
  .first-article .block_cont > svg {
    position: absolute;
    left: 15px;
    top: 10px;
    z-index: 0; }
  .first-article .block_cont .block_top {
    width: 50%;
    padding-right: 50px;
    position: relative;
    z-index: 2; }
    .first-article .block_cont .block_top img {
      width: 100%;
      max-height: 320px;
      object-fit: cover;
      border-radius: 5px; }
  .first-article .block_cont .block_bas {
    width: 50%; }
    .first-article .block_cont .block_bas h3 {
      font-family: "cormorant-garamond", serif;
      font-weight: normal;
      font-size: 28px;
      letter-spacing: 0.04em;
      line-height: 30px;
      max-width: 400px;
      color: #fff; }
    .first-article .block_cont .block_bas .blog_desc {
      font-family: "lato", sans-serif;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.05em;
      line-height: 23px;
      color: #fff; }
  .first-article .block_cont .prestablog_more {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .first-article .block_cont .prestablog_more svg {
      margin-left: 10px; }

#module-prestablog-blog h1 {
  font-family: "cormorant-garamond", serif;
  font-weight: 400;
  font-size: 45px;
  letter-spacing: .07em;
  text-align: center;
  color: #001253; }
  #module-prestablog-blog h1 > span {
    display: block; }
    #module-prestablog-blog h1 > span span {
      color: #c2915e;
      position: relative;
      margin-bottom: 30px;
      display: inline-block; }
      #module-prestablog-blog h1 > span span:after {
        content: "";
        width: 98%;
        height: 4px;
        background-image: url(../img/border_title_blue.svg);
        position: absolute;
        bottom: -2px;
        background-size: cover;
        left: 0; }

#module-prestablog-blog .prestablog_pagination {
  padding-bottom: 100px;
  padding-top: 70px;
  margin-top: 30px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }
  #module-prestablog-blog .prestablog_pagination:before {
    content: "";
    width: 100vw;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background: #FBF7F2;
    z-index: -1; }
  #module-prestablog-blog .prestablog_pagination a {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 0;
    font-family: "lato", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    color: #C2915E;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    border: none;
    background: transparent; }
  #module-prestablog-blog .prestablog_pagination .current {
    color: #fff;
    background-color: #C2915E;
    border-color: #C2915E;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 0;
    font-family: lato,sans-serif;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    font-weight: bold; }
  #module-prestablog-blog .prestablog_pagination .next {
    width: auto;
    height: auto;
    position: absolute;
    right: 0; }
    #module-prestablog-blog .prestablog_pagination .next .arrow-ek {
      width: 33px;
      height: 33px;
      background: #C2915E;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      margin-left: 15px; }
  #module-prestablog-blog .prestablog_pagination .prev {
    width: auto;
    height: auto;
    position: absolute;
    left: 0;
    border-radius: 50%;
    font-weight: 400;
    color: #c2915e;
    border: none;
    background: transparent;
    display: flex;
    align-items: center; }
    #module-prestablog-blog .prestablog_pagination .prev .arrow-ek {
      width: 33px;
      height: 33px;
      background: #c2915e;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      margin-right: 15px;
      transform: rotate(180deg); }

.ek_prestablog .blog-grid:not(.first-article) {
  margin-bottom: 30px; }
  .ek_prestablog .blog-grid:not(.first-article) .block_top a > img {
    max-width: 100%;
    border-radius: 8px;
    max-height: 260px;
    width: 100%;
    object-fit: cover; }
  .ek_prestablog .blog-grid:not(.first-article) h3 a {
    font-family: "cormorant-garamond", serif;
    font-weight: 600;
    font-size: 28px;
    letter-spacing: 0.04em;
    line-height: 30px;
    color: #001253; }
  .ek_prestablog .blog-grid:not(.first-article) .block_bas {
    padding-top: 15px; }
    .ek_prestablog .blog-grid:not(.first-article) .block_bas .blog_desc {
      font-family: "lato", sans-serif;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.05em;
      line-height: 23px;
      color: #000; }
  .ek_prestablog .blog-grid:not(.first-article) .prestablog_more {
    text-align: right; }
    .ek_prestablog .blog-grid:not(.first-article) .prestablog_more a {
      font-family: "lato", sans-serif;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.08em;
      line-height: 23px;
      color: #c2915e;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .ek_prestablog .blog-grid:not(.first-article) .prestablog_more a svg {
        margin-left: 10px; }

#prestablogfront, .prestablogExtra {
  border: none !important;
  max-width: 1100px;
  margin: 0 auto; }
  #prestablogfront .img-blog img, .prestablogExtra .img-blog img {
    width: 100%;
    max-height: 700px;
    object-fit: cover;
    border-radius: 8px; }
  #prestablogfront #prestablog_article, .prestablogExtra #prestablog_article {
    font-family: "cormorant-garamond", serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    color: #001253;
    position: relative;
    margin-bottom: 40px;
    overflow: visible; }
    #prestablogfront #prestablog_article:before, .prestablogExtra #prestablog_article:before {
      content: "";
      width: 245px;
      height: 4px;
      background-image: url(../img/after-marque.svg);
      position: absolute;
      bottom: -2px;
      background-size: cover;
      left: 50%;
      transform: translateX(-50%); }
  #prestablogfront .content-article, .prestablogExtra .content-article {
    max-width: 880px;
    margin: 0 auto; }

.infos-blog {
  position: relative; }
  .infos-blog:before {
    content: "";
    width: 100vw;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background: #fbf7f2;
    z-index: -1; }
  .infos-blog .share {
    padding-bottom: 100px;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: end;
    max-width: 1100px;
    margin: 0 auto; }
  .infos-blog .label {
    white-space: nowrap;
    margin-right: 10px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-family: "lato", sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.1em;
    line-height: 23px;
    text-align: left;
    color: #001253;
    text-transform: uppercase; }
    .infos-blog .label svg {
      margin-right: 8px;
      margin-top: 2px; }
  .infos-blog .soc-buttons {
    height: auto;
    display: flex;
    align-items: center;
    width: fit-content; }
    .infos-blog .soc-buttons li {
      width: auto !important;
      margin-right: 5px; }
      .infos-blog .soc-buttons li a {
        padding: 0 !important;
        background: transparent !important;
        font-size: 0;
        width: auto; }
        .infos-blog .soc-buttons li a svg path {
          fill: #001253 !important; }

/** COMPONENT **/
.ps-alert-error {
  margin-bottom: 0; }

.ps-alert-error .item,
.ps-alert-success .item {
  align-items: center;
  border: 2px #dc3545 solid;
  display: flex;
  background-color: #dc3545;
  margin-bottom: 1rem; }
  .ps-alert-error .item i,
  .ps-alert-success .item i {
    border: 15px #dc3545 solid;
    display: flex; }
    .ps-alert-error .item i svg,
    .ps-alert-success .item i svg {
      background-color: #dc3545;
      width: 24px;
      height: 24px; }
  .ps-alert-error .item p,
  .ps-alert-success .item p {
    background-color: #fff;
    margin: 0;
    padding: 1rem;
    width: 100%; }

.ps-alert-success {
  padding: 0.25rem 0.25rem 2.75rem 0.25rem; }
  .ps-alert-success .item {
    border-color: #28a745;
    background-color: #28a745; }
    .ps-alert-success .item i {
      border-color: #28a745; }
      .ps-alert-success .item i svg {
        background-color: #28a745; }

.social-sharing--product {
  margin: 1rem 0; }

.modal .social-sharing--product {
  margin: 0; }

.social-share-btn {
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  margin: 0 0.5rem;
  background-color: #f1f1f1;
  background-repeat: no-repeat;
  background-position: center center; }
  .social-share-btn.facebook {
    background-image: url(../img/facebook.svg); }
  .social-share-btn.twitter {
    background-image: url(../img/twitter.svg); }
  .social-share-btn.linkedin {
    background-image: url(../img/linkedin.svg); }
  .social-share-btn.pinterest {
    background-image: url(../img/pinterest.svg); }
  .social-share-btn.rss {
    background-image: url(../img/rss.svg); }
  .social-share-btn.youtube {
    background-image: url(../img/youtube.svg); }
  .social-share-btn.vimeo {
    background-image: url(../img/vimeo.svg); }
  .social-share-btn.instagram {
    background-image: url(../img/instagram.svg); }

.social-share-btn--product {
  background-size: 80%;
  background-color: #fff;
  border-radius: 50%;
  transition: box-shadow 0.3s ease-in-out; }
  .social-share-btn--product.facebook {
    background-image: url(../img/facebook-gray.svg); }
    .social-share-btn--product.facebook:hover {
      background-image: url(../img/facebook-blue.svg); }
  .social-share-btn--product.twitter {
    background-image: url(../img/twitter-gray.svg); }
    .social-share-btn--product.twitter:hover {
      background-image: url(../img/twitter-blue.svg); }
  .social-share-btn--product.googleplus {
    background-image: url(../img/gplus-gray.svg); }
    .social-share-btn--product.googleplus:hover {
      background-image: url(../img/gplus-blue.svg); }
  .social-share-btn--product.pinterest {
    background-image: url(../img/pinterest-gray.svg); }
    .social-share-btn--product.pinterest:hover {
      background-image: url(../img/pinterest-blue.svg); }

.social-share-btn--footer {
  transition: background-color 0.3s ease-in-out; }
  .social-share-btn--footer:hover {
    background-color: #001253; }

.product-line-actions {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .product-line-actions .input-group.bootstrap-touchspin {
    max-width: 130px; }

.product-line-grid .product-line__title {
  margin-bottom: 0.75rem;
  display: inline-block;
  color: #212529; }

.product-line-grid .product-price {
  display: flex;
  align-items: center; }
  .product-line-grid .product-price .discount {
    font-size: 0.875rem;
    line-height: 1; }

.product-line-grid .product-discount {
  margin-right: 0.5rem; }

.product-line__img {
  margin-right: 1rem; }

@media (max-width: 991.98px) {
  .product-line__img {
    width: 100px;
    height: auto; }
  .product-line-actions {
    justify-content: flex-end;
    margin-top: 1rem; }
    .product-line-actions .product-price {
      margin: 0 2rem; } }

@media (max-width: 991.98px) {
  .product-line__img {
    width: 50px; }
  .product-line-actions .product-price {
    margin: 0 1rem; } }

.cart__card-body {
  position: relative; }
  .cart__card-body .cart__card-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    display: none;
    background-color: rgba(255, 255, 255, 0.85);
    opacity: 0;
    transition: opacity 0.15s linear;
    z-index: 3; }
  .cart__card-body.is--loading .cart__card-loader {
    display: flex;
    opacity: 1; }

.nav-tabs--center {
  justify-content: center; }

@media (max-width: 991.98px) {
  .checkout {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    padding: 1rem 1rem 2rem;
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(5px); }
    .checkout a {
      width: 100%; } }

.product-line__body {
  padding-right: 0.25rem; }

.total-value {
  font-weight: 700; }

.table__title-head {
  margin-bottom: 0;
  font-size: 1rem; }

/* CART */
.promo-code {
  margin-left: -1.25rem;
  margin-right: -1.25rem; }

.promo-code-alert {
  display: none; }

.cancel-promo {
  text-align: center; }

.card-body .separator, .page-content .separator, .block-categories .separator, .address-body .separator {
  margin-left: -1.25rem;
  margin-right: -1.25rem; }

.cart-summary-line {
  display: flex;
  justify-content: space-between; }

.cart-total {
  font-weight: bold; }

.card-body--summary .cart-total .label {
  margin-bottom: 0; }

.card-body--summary.card-body, .card-body--summary.page-content, .card-body--summary.block-categories, .card-body--summary.address-body {
  padding-bottom: 0; }

.media-list__item:not(:last-child) {
  margin-bottom: 0.5rem; }

.link__showsummary[aria-expanded=true] .material-icons {
  transform: rotateZ(180deg); }

.cart-item:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 1rem;
  padding-bottom: 1rem; }

.product-line-info {
  margin-bottom: 0.5rem; }

.promo-code-button {
  text-align: center; }
  .promo-code-button .collapse-button[aria-expanded=true] {
    display: none; }

.promo-highlighted {
  text-align: center;
  font-size: 0.875rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem; }

.promo-discounts {
  font-weight: 700;
  text-align: center;
  color: #495057; }
  .promo-discounts .code {
    color: #d04d00; }

.form__add-voucher {
  margin-bottom: 1rem; }

.promo-code__content {
  padding: 1.25rem;
  background-color: #e6e6e6; }

.promo-code .collapse-button {
  text-align: center;
  display: block;
  color: #001253; }

.block-categories a {
  color: #212529; }

.category-sub__item {
  display: block;
  width: 100%; }

.category-sub__item--0 {
  border-bottom: 1px solid #f1f1f1;
  padding: 0.25rem 0; }

.category-sub__item:not(.category-sub__item--0):not(.category-sub__item--1) {
  padding-left: 0.25rem; }

.block-categories .collapse-icons .remove {
  display: none; }

.block-categories .collapse-icons[aria-expanded=true].remove {
  display: block; }

.block-categories .collapse-icons[aria-expanded=true].add {
  display: none; }

.account-links > a {
  margin-bottom: 30px; }

.account-links .link-item {
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  height: 100%; }
  .account-links .link-item i {
    margin: 0 auto 1rem;
    font-size: 3rem; }

.address {
  height: 100%; }

.address-header {
  background-color: transparent; }

.address-footer {
  display: flex;
  justify-content: space-between; }

.form--100 {
  min-width: 100%;
  width: 100%; }

.l-footer {
  color: #fff; }
  .l-footer .cms-page-link,
  .l-footer .account-list a {
    color: #fff; }
    .l-footer .cms-page-link:hover,
    .l-footer .account-list a:hover {
      color: #001253; }

.footer-container {
  margin-top: 1rem; }

.footer__title {
  color: #fff;
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  margin-bottom: 0.5rem; }
  .footer__title:visited, .footer__title:hover {
    color: #fff; }
  .footer__title--mobile {
    display: none;
    margin-bottom: 0;
    padding: 0.5rem 0;
    border-bottom: 1px solid #f1f1f1; }
    .footer__title--mobile:after {
      content: "\e313";
      font-family: 'Material Icons';
      font-feature-settings: 'liga' 1;
      position: absolute;
      right: 15px;
      transition: .3s transform ease-in-out;
      font-size: 1.5rem; }
    .footer__title--mobile[data-toggle="collapse"][aria-expanded="true"]:after {
      transform: rotate(180deg); }

@media (max-width: 991.98px) {
  .footer__title--desktop {
    display: none; }
  .footer__title--mobile {
    display: block; } }

.footer__copyright {
  text-align: center;
  font-size: 0.875rem; }

.header__nav {
  align-items: center; }

.header-nav__nav2 {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.header-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }

.header-mobile__user-cart {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.header__top {
  display: flex;
  justify-content: space-between;
  position: static; }

.carousel a {
  display: block; }
  .carousel a:not(:first-child) {
    display: none; }

.slider-caption {
  position: absolute;
  top: 10%;
  left: 90px;
  margin-bottom: 0;
  color: #fff;
  max-width: 340px; }

.slick-slide:not(.slick-current) .img-carousel.lazyload {
  display: none !important; }

.slick-slide figure {
  position: absolute;
  top: 0; }

body .ps17.ui-menu.ui-jolisearch {
  position: fixed;
  border: 0;
  background: none; }
  @media screen and (max-width: 769px) {
    body .ps17.ui-menu.ui-jolisearch {
      width: calc(100vw - 25px) !important;
      max-width: calc(100vw - 25px); } }
  body .ps17.ui-menu.ui-jolisearch .no-results-found.ui-menu-item,
  body .ps17.ui-menu.ui-jolisearch .jolisearch-body {
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    border: 1px solid #f1f1f1;
    background: #fff; }
    @media screen and (max-width: 769px) {
      body .ps17.ui-menu.ui-jolisearch .no-results-found.ui-menu-item,
      body .ps17.ui-menu.ui-jolisearch .jolisearch-body {
        box-shadow: none;
        border-color: #ced4da; } }
  body .ps17.ui-menu.ui-jolisearch .no-results-found.ui-menu-item {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    color: #000; }
    body .ps17.ui-menu.ui-jolisearch .no-results-found.ui-menu-item .ui-state-focus {
      border: 0;
      background: none;
      margin: 0; }
    body .ps17.ui-menu.ui-jolisearch .no-results-found.ui-menu-item a.ui-state-active {
      font-weight: inherit; }
    body .ps17.ui-menu.ui-jolisearch .no-results-found.ui-menu-item .jolisearch-post:hover {
      background: none;
      border: 0; }
  body .ps17.ui-menu.ui-jolisearch .jolisearch-body {
    padding: 20px 15px; }
    @media screen and (max-width: 769px) {
      body .ps17.ui-menu.ui-jolisearch .jolisearch-body {
        padding: 10px 0; } }
    body .ps17.ui-menu.ui-jolisearch .jolisearch-body h4 {
      color: #000;
      letter-spacing: 0.01em; }
    body .ps17.ui-menu.ui-jolisearch .jolisearch-body section[role="main"] .more-results a {
      display: inline-block;
      padding: 0.3rem 1.25rem 0.25rem;
      background: none;
      border: 0;
      color: #000;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      transition: all ease 0.33s; }
      body .ps17.ui-menu.ui-jolisearch .jolisearch-body section[role="main"] .more-results a:focus, body .ps17.ui-menu.ui-jolisearch .jolisearch-body section[role="main"] .more-results a:active, body .ps17.ui-menu.ui-jolisearch .jolisearch-body section[role="main"] .more-results a:hover {
        background: #001253;
        border: 0;
        color: #fff; }
    @media screen and (min-width: 1200px) {
      body .ps17.ui-menu.ui-jolisearch .jolisearch-body section[role="main"] .col-lg-3-12 {
        width: calc(25% - 10px); } }
    @media screen and (min-width: 992px) {
      body .ps17.ui-menu.ui-jolisearch .jolisearch-body section[role="main"] .col-lg-3-12 {
        width: calc(33% - 10px); } }
    body .ps17.ui-menu.ui-jolisearch .jolisearch-body section[role="main"] .product {
      margin: 5px 0;
      overflow: hidden;
      transition: all ease 0.3s;
      clear: none; }
      body .ps17.ui-menu.ui-jolisearch .jolisearch-body section[role="main"] .product:hover {
        background-color: #f8f9fa;
        border: 1px solid #f8f9fa; }
      body .ps17.ui-menu.ui-jolisearch .jolisearch-body section[role="main"] .product img {
        margin: -10px -15px 0.5rem;
        max-width: calc(100% + 30px);
        width: calc(100% + 30px); }
      body .ps17.ui-menu.ui-jolisearch .jolisearch-body section[role="main"] .product .jolisearch-product span.product-name {
        color: #000;
        text-align: center;
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 0.6rem; }
      body .ps17.ui-menu.ui-jolisearch .jolisearch-body section[role="main"] .product .jolisearch-product span.product-price {
        font-size: 16px;
        font-weight: 800; }
    body .ps17.ui-menu.ui-jolisearch .jolisearch-body section[role="main"] .items-count {
      font-size: 11px;
      display: inline-block;
      padding: 0.1rem .5rem;
      border-radius: 0;
      background-color: #001253;
      color: #fff;
      font-weight: 700;
      vertical-align: 3px; }
    body .ps17.ui-menu.ui-jolisearch .jolisearch-body aside[role="complementary"] > div {
      margin-bottom: 1rem;
      padding-bottom: 0; }
    body .ps17.ui-menu.ui-jolisearch .jolisearch-body aside[role="complementary"] li {
      padding-top: 0;
      padding-bottom: 0;
      border: 0; }
      body .ps17.ui-menu.ui-jolisearch .jolisearch-body aside[role="complementary"] li:hover {
        background: none;
        border: 0; }
      body .ps17.ui-menu.ui-jolisearch .jolisearch-body aside[role="complementary"] li a {
        display: inline-block;
        padding: 3px 4px 2px 4px; }
        body .ps17.ui-menu.ui-jolisearch .jolisearch-body aside[role="complementary"] li a:hover {
          color: #001253; }

.modal.fade .modal-dialog__offcanvas {
  width: 350px;
  height: 100%;
  margin: 0;
  max-width: 90%;
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  transition: transform .3s ease-out; }

.modal.show .modal-dialog__offcanvas {
  transform: translateX(0); }

.modal.fade .modal-dialog__offcanvas.modal-dialog__offcanvas--right {
  right: 0;
  transform: translateX(100%); }

.modal.show .modal-dialog__offcanvas.modal-dialog__offcanvas--right {
  transform: translateX(0); }

.modal-dialog__offcanvas .modal-content {
  height: inherit;
  border-width: 0;
  border-radius: 0; }

.modal-dialog__offcanvas .modal-header {
  border-radius: inherit; }

.modal-dialog__offcanvas .modal-body {
  overflow-y: auto;
  height: inherit; }

.products {
  align-items: stretch; }

@media (min-width: 992px) {
  .product-miniature {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .layout-full-width .product-miniature {
    flex: 0 0 25%;
    max-width: 25%; } }

@media (max-width: 320px) {
  .product-miniature {
    flex: 0 0 100%;
    max-width: 100%; } }

.slick-slide .product-miniature {
  max-width: 100%; }

/* Product miniature*/
.card-product.card, .card-product.page-wrapper, .card-product.block-categories, .account-links .card-product.link-item, .card-product.address {
  height: 100%;
  position: relative; }
  .card-product.card:hover .highlighted-informations, .card-product.page-wrapper:hover .highlighted-informations, .card-product.block-categories:hover .highlighted-informations, .account-links .card-product.link-item:hover .highlighted-informations, .card-product.address:hover .highlighted-informations {
    transform: translateY(0); }
  .card-product.card .discount, .card-product.page-wrapper .discount, .card-product.block-categories .discount, .account-links .card-product.link-item .discount, .card-product.address .discount {
    display: none; }

.card-img-top {
  overflow: hidden; }
  .card-img-top .product-thumbnail img {
    width: 100%; }

.highlighted-informations {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  transform: translateY(100%);
  transition: transform .3s; }
  .card-product:hover .highlighted-informations {
    transform: translateY(0); }

.quick-view {
  cursor: pointer; }

.product-flags {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  margin-bottom: 0; }

.page-content--product .product-flags {
  left: 0; }

.product-flag {
  top: 0;
  margin-top: 0.5rem;
  padding: 0.25rem;
  color: #fff;
  background: #001253;
  text-transform: uppercase;
  font-weight: 600; }
  .product-flag.discount-product {
    background: #d04d00; }

.color {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  background-size: contain; }

.product-title {
  color: #495057;
  text-decoration: none;
  text-align: center;
  font-weight: 400; }
  .product-title a {
    color: #495057; }
    .product-title a:hover {
      color: #001253;
      border-bottom: 1px solid transparent; }
  .card-product:hover .product-title {
    color: #001253; }
    .card-product:hover .product-title a {
      color: #001253; }

/* PRODUCT PAGE */
.product-img:not(:first-child) .rc {
  display: none; }

.slick-initialized .product-img .rc {
  display: block; }

.product-thumbs {
  width: 320px;
  height: 110px;
  margin: 0 auto; }
  .product-thumbs[data-count="2"] {
    width: 210px; }
  .product-thumbs .slick-slide > div {
    cursor: pointer; }
  .product-thumbs:not(.slick-initialized) > div:not(:first-child) {
    display: none; }

.product-thumb {
  margin: 5px;
  width: 100px;
  height: 100px;
  border: 2px solid transparent; }
  .product-thumb.slick-current {
    border: 2px solid #001253; }

.btn-zoom {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  cursor: pointer;
  display: none;
  padding: 0;
  line-height: 1; }
  .btn-zoom .material-icons {
    font-size: 3rem; }

.images-container:hover .btn-zoom {
  display: block; }

.label-color {
  line-height: 1;
  margin-right: 0.5rem; }

.product-variants-item .color {
  width: 2rem;
  height: 2rem;
  border: 2px solid transparent; }

.input-color:checked + span, .input-color:hover + span, .input-radio:checked + span, .input-radio:hover + span {
  border-color: #232323; }

.product__product-price.has-discount .current-price {
  display: inline-block; }

.price {
  font-weight: 700; }
  .price.current-price-discount {
    color: #d04d00;
    font-weight: 700; }

.regular-price {
  color: #7a7a7a;
  font-size: 0.875rem;
  font-weight: 700;
  text-decoration: line-through; }

.discount {
  text-transform: uppercase;
  color: #FFF;
  background-color: #d04d00;
  font-weight: 600;
  display: inline-block;
  padding: 0.25rem; }

.btn-add-to-cart {
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap; }

.product-tabs .nav-tabs {
  font-size: 0.9rem; }

.cart-content {
  padding: 1rem;
  background: #f8f9fa; }

.pack-product-container {
  padding: 0 1rem;
  font-size: 0.875rem; }

.mask {
  width: 55px; }

.thumb-mask {
  padding: 1rem 0; }

.pack-product__img {
  border: 1px solid #f1f1f1; }

.pack-product-quantity {
  border-left: 1px solid #f1f1f1; }

.pack-product-price {
  padding-right: 0.5rem; }

.pack-product-quantity {
  padding-left: 1rem; }

.modal-cart__image {
  margin-right: 1rem;
  max-width: 200px;
  height: auto; }

.modal-cart__name {
  color: #001253;
  font-weight: 700; }

.product-total {
  font-weight: 700; }

.cart-content > p:not(:last-child) {
  margin-bottom: 0.5rem; }

@media (max-width: 991.98px) {
  .modal-cart__image {
    max-width: 120px; }
  .blockcart-modal .divide-right {
    display: flex;
    justify-content: center; }
  .blockcart-modal .modal-footer {
    position: fixed;
    bottom: 0;
    background: #fff;
    justify-content: center;
    left: 0;
    width: 100%;
    box-shadow: 0px -0.25rem 0.5rem rgba(0, 0, 0, 0.15); }
  .blockcart-modal .modal-body {
    margin-bottom: 4.25rem; } }

@media (max-width: 575.98px) {
  .modal-cart__image {
    max-width: 70px; } }

.btn-add-to-cart .btn-add-to-cart__spinner {
  width: 1.25rem;
  height: 1.25rem;
  border-width: 2px;
  display: none; }

.btn-add-to-cart.is--loading .btn-add-to-cart__spinner {
  display: inline-block; }

.btn-add-to-cart.is--loading .btn-add-to-cart__icon {
  display: none; }

.products-selection {
  justify-content: space-between;
  align-items: center; }

.pack-miniature-item {
  margin-bottom: 0.5rem; }

.ratio1_1 {
  display: block; }

.pack-product-name {
  margin: 0 1.5rem;
  flex-grow: 1; }

.product-miniature {
  margin-bottom: 1rem; }

.product__card-img {
  position: relative; }

.product__card-desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }

.highlighted-informations {
  text-align: center;
  padding: 0.5rem; }

.quantity__label {
  margin-bottom: 0;
  margin-top: 1rem; }

.product-add-to-cart .product-quantity {
  margin-top: -1rem; }

.product-add-to-cart .qty {
  margin: 0 1rem;
  margin-top: 1rem; }

.product-add-to-cart .add {
  margin-top: 1rem; }

.product-customization {
  margin-bottom: 1.5rem; }

.product-discounts {
  margin-bottom: 1.5rem; }

.product__quantity {
  margin-bottom: 0; }

.sort-by-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center; }

.total__item-show {
  text-align: center;
  font-size: 0.875rem;
  margin-top: 1rem; }

.variant-links {
  display: flex;
  justify-content: center; }

.product__download {
  text-transform: uppercase; }

.card--address {
  height: 100%;
  text-align: center;
  justify-content: center; }

.invoice__address {
  margin-top: 1rem; }

.invoice-address {
  height: 100%;
  text-align: center;
  justify-content: center; }

.nav-tabs {
  justify-content: center; }

.search-widget {
  max-width: 450px;
  margin: 0 auto; }

.search-widget__group {
  position: relative; }

.search-widget__input-right {
  padding-right: 50px; }

.search-widget__btn {
  position: absolute;
  right: 0;
  top: 0; }

@media (max-width: 991.98px) {
  .search-widget {
    max-width: unset; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-arrow {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 1; }
  .slick-arrow > i {
    font-size: 2rem;
    line-height: 1;
    background: #fff;
    border-radius: 50%; }

.slick-prev {
  left: 0; }

.slick-next {
  right: 0; }

.slick-slide figure {
  margin: 0; }

.slick__arrow-outside .slick-prev {
  transform: translateX(-50px); }

.slick__arrow-outside .slick-next {
  transform: translateX(50px); }

.slick__arrow-large .slick-arrow i {
  font-size: 3rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background: transparent;
  color: #fff; }

@media (min-width: 992px) {
  .product-comment-modal .modal-dialog {
    max-width: 774px; } }

.product-comment-modal .modal-footer {
  margin-right: -1rem;
  margin-left: -1rem; }

.product-comment-modal .modal-body {
  padding-bottom: 0; }

.modal--product-comment-posted-modal .modal-header,
.modal--report-comment-posted .modal-header {
  color: #28a745; }

.product-comment-post-error-message .modal-header {
  color: #dc3545; }

.grade-stars,
.comments-note {
  display: flex; }

.star {
  color: #f1ab00; }

/** product list **/
.product-list-reviews .grade-stars {
  justify-content: center; }

.product-list-reviews .star .material-icons {
  font-size: 1rem; }

/** product page **/
.product-comments {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.product-comments-additional-info {
  margin: 1rem 0; }

.comments-note {
  margin-bottom: 0.5rem; }
  .comments-note .grade-stars {
    margin-left: 0.25rem; }

.comments__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.product-comment-list-item {
  background: #fff;
  margin-bottom: 1rem;
  padding: 1rem; }

.comment-infos .grade-stars {
  margin-bottom: 1rem; }

.comment-infos .comment-date,
.comment-infos .comment-author {
  font-size: 0.875rem; }

/* comment list */
.product-comments__pagination li:not(.disabled) span:not(.current) {
  cursor: pointer; }

/* MODAL COMMENT */
/* radio input star ratig */
.starability-basic {
  display: block;
  position: relative;
  width: 150px;
  min-height: 60px;
  padding: 0;
  border: none;
  min-height: auto; }
  .starability-basic > input {
    position: absolute;
    margin-right: -100%;
    opacity: 0; }
  .starability-basic > input:checked ~ label, .starability-basic > input:checked ~ .label,
  .starability-basic > input:focus ~ label,
  .starability-basic > input:focus ~ .label {
    background-position: 0 0; }
  .starability-basic > input:checked + label, .starability-basic > input:checked + .label,
  .starability-basic > input:focus + label,
  .starability-basic > input:focus + .label {
    background-position: 0 -30px; }
  .starability-basic > input[disabled]:hover + label, .starability-basic > input[disabled]:hover + .label {
    cursor: default; }
  .starability-basic > input:not([disabled]):hover ~ label, .starability-basic > input:not([disabled]):hover ~ .label {
    background-position: 0 0; }
  .starability-basic > input:not([disabled]):hover + label, .starability-basic > input:not([disabled]):hover + .label {
    background-position: 0 -30px; }
  .starability-basic > input:not([disabled]):hover + label::before, .starability-basic > input:not([disabled]):hover + .label::before {
    opacity: 1; }
  
  .starability-basic > input:focus + label,
  .starability-basic > input:focus + .label {
    outline: 1px dotted #999; }
  .starability-basic .starability-focus-ring {
    position: absolute;
    left: 0;
    width: 100%;
    height: 30px;
    outline: 2px dotted #999;
    pointer-events: none;
    opacity: 0; }
  .starability-basic > .input-no-rate:focus ~ .starability-focus-ring {
    opacity: 1; }
  .starability-basic > label, .starability-basic > .label {
    position: relative;
    display: inline-block;
    float: left;
    width: 30px;
    height: 30px;
    font-size: 0.1em;
    color: transparent;
    cursor: pointer;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAA8CAMAAABGivqtAAAAxlBMVEUAAACZmZn2viTHuJ72viOampqampr1viSampr3vySampqdnZ34wiX1vSSampr1vSOZmZmampr1viT2vSOampr2viT2viSampr2viSampr2vyX4vyWbm5v3vSSdnZ32wSadnZ36wCWcnJyZmZn/wSr/2ySampr2vSP2viSZmZn2vSSZmZn2vST2viSampr2viSbm5ubm5uZmZn1vSSampqbm5v2vSWampqampr3vSf5wiT5vyagoKD/xCmkpKT/yCSZmZn1vSO4V2dEAAAAQHRSTlMA+vsG9fO6uqdgRSIi7+3q39XVqZWVgnJyX09HPDw1NTAwKRkYB+jh3L6+srKijY2Ef2lpYllZUU5CKigWFQ4Oneh1twAAAZlJREFUOMuV0mdzAiEQBmDgWq4YTWIvKRqT2Htv8P//VJCTGfYQZnw/3fJ4tyO76KE0m1b2fZu+U/pu4QGlA7N+Up5PIz9d+cmkbSrSNr9seT3GKeNYIyeO5j16S28exY5suK0U/QKmmeCCX6xs22hJLVkitMImxCvEs8EG3SCRCN/ViFPqnq5epIzZ07QJJvkM9Tkz1xnkmXbfSvR7f4H8AtXBkLGj74mMvjM1+VHZpAZ4LM4K/LBWEI9jwP71v1ZEQ6dyvQMf8A/1pmdZnKce/VH1iIsdte4U8VEtY23xOujxtFpWDgKbfjD2YeEhY0OzfjGeLyO/XfnNpAcmcjDwKOXRfU1IyiTRyEkaiz67pb9oJHJb9vVqKfgjLBPyF5Sq9T0KmSUhQmtiQrJGPHVi0DoSabj31G2gW3buHd0pY85lNdcCk8xlNDPXMuSyNiwl+theIb9C7RLIpKvviYy+M6H8qGwSAp6Is19+GP6KxwnggJ/kq6Jht5rnRQA4z9zyRRaXssvyqp5I6Vutv0vkpJaJtnjpz/8B19ytIayazLoAAAAASUVORK5CYII=");
    background-repeat: no-repeat;
    background-position: 0 -30px; }
    .starability-basic > label::before, .starability-basic > .label::before {
      content: '';
      position: absolute;
      display: block;
      height: 30px;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAA8CAMAAABGivqtAAAAxlBMVEUAAACZmZn2viTHuJ72viOampqampr1viSampr3vySampqdnZ34wiX1vSSampr1vSOZmZmampr1viT2vSOampr2viT2viSampr2viSampr2vyX4vyWbm5v3vSSdnZ32wSadnZ36wCWcnJyZmZn/wSr/2ySampr2vSP2viSZmZn2vSSZmZn2vST2viSampr2viSbm5ubm5uZmZn1vSSampqbm5v2vSWampqampr3vSf5wiT5vyagoKD/xCmkpKT/yCSZmZn1vSO4V2dEAAAAQHRSTlMA+vsG9fO6uqdgRSIi7+3q39XVqZWVgnJyX09HPDw1NTAwKRkYB+jh3L6+srKijY2Ef2lpYllZUU5CKigWFQ4Oneh1twAAAZlJREFUOMuV0mdzAiEQBmDgWq4YTWIvKRqT2Htv8P//VJCTGfYQZnw/3fJ4tyO76KE0m1b2fZu+U/pu4QGlA7N+Up5PIz9d+cmkbSrSNr9seT3GKeNYIyeO5j16S28exY5suK0U/QKmmeCCX6xs22hJLVkitMImxCvEs8EG3SCRCN/ViFPqnq5epIzZ07QJJvkM9Tkz1xnkmXbfSvR7f4H8AtXBkLGj74mMvjM1+VHZpAZ4LM4K/LBWEI9jwP71v1ZEQ6dyvQMf8A/1pmdZnKce/VH1iIsdte4U8VEtY23xOujxtFpWDgKbfjD2YeEhY0OzfjGeLyO/XfnNpAcmcjDwKOXRfU1IyiTRyEkaiz67pb9oJHJb9vVqKfgjLBPyF5Sq9T0KmSUhQmtiQrJGPHVi0DoSabj31G2gW3buHd0pY85lNdcCk8xlNDPXMuSyNiwl+theIb9C7RLIpKvviYy+M6H8qGwSAp6Is19+GP6KxwnggJ/kq6Jht5rnRQA4z9zyRRaXssvyqp5I6Vutv0vkpJaJtnjpz/8B19ytIayazLoAAAAASUVORK5CYII=");
      background-position: 0 30px;
      pointer-events: none;
      opacity: 0; }
    .starability-basic > label:nth-of-type(5)::before, .starability-basic > .label:nth-of-type(5)::before {
      width: 120px;
      left: -120px; }
    .starability-basic > label:nth-of-type(4)::before, .starability-basic > .label:nth-of-type(4)::before {
      width: 90px;
      left: -90px; }
    .starability-basic > label:nth-of-type(3)::before, .starability-basic > .label:nth-of-type(3)::before {
      width: 60px;
      left: -60px; }
    .starability-basic > label:nth-of-type(2)::before, .starability-basic > .label:nth-of-type(2)::before {
      width: 30px;
      left: -30px; }
    .starability-basic > label:nth-of-type(1)::before, .starability-basic > .label:nth-of-type(1)::before {
      width: 0px;
      left: 0px; }
    @media screen and (min-resolution: 192dpi) {
      .starability-basic > label, .starability-basic > .label {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAB4CAMAAACZ62E6AAABAlBMVEUAAACZmZmampr2vSObm5v/yiufn5+ampr1viP1viSZmZn2viOZmZmampqampr2viSampqampqcnJz5vyScnJz3wSf/wyn/xiujo6Oqqqr/0C/1vSOampr2viP2viOampr2viP2vST2viOampqampqampr1vyP3viSampr2vyT4vyX3viSbm5ubm5v5wCT8xSmgoKCampqampr3vyb2wiWenp72viOampqZmZmampr2viP2viP1viSampqbm5v2vyT3viObm5v4vyadnZ34wSSbm5v2viSZmZn2viP2vST2viP2viT1viOZmZn2viT2viX3viT3vyb2vyOZmZn1vSOZmZlNN+fKAAAAVHRSTlMA9uz4PQwS8O7r5+fTw4yMelw2MB0dFRELBgbS+/Hfu7uxqKWdg4N7ZmZMPi8pKRgPs0w7Nhb14drKw6Gck21tXkNDIyMZ1rDLycTBtaqVknlfV0sGP8ZwAAADW0lEQVRYw9zWvYqDQBSG4TPDoCAqKhYKQgoVLFaIgZCkiCBBUqVazv3fyu4aEXWdM85Uy779A+LP58AfTQgw73AwtxFiZIwbxMbUfuB3H4b49YNfZrbGodoI52+cm9hH9sbZwwAXOFbo2zjDsSzWxnecuuvaM8MpdtbEPs7y9azF5phZWrjERaWOPdpLbB81cICrgv3W4mvMLbU6RmFQeA5u5HhFEEbHLdWLsMxvHJXxW16Goh+ZqPyny1Az5j79SsCJoWHsBNAxQ9sNF26bWFuMC8v1LY+mmeTadjaqtaNnnXoxWBcde1nNWnzdb68xrOqvu22/MTzuPutujpJ122NvluSb8tTWk85CclDZQwLS0oa2TQpEKacsJy0kSJaQOKJxROKKxhWJ7zS+k9ijsUdim8Y2ZWNUFBP4pMKfOv8onX9WrsI5gd3VVLXtatxcuU0znGUHCUAS2DgrS6mT6hTzrXEjfIZj5Dk2xKkihqm4wKlQfQRqalhUP9UHo3FIPAG/Et44JVLsDDf0JHmB3OEByOwZES8hSAsviGjBdh3ylh6plmMnW4IyAUVJWcE/76vTell1EIaiMBwIAcWBA9GC0lIdKFXQQUsHVVCklN7ojf3+z3JOxYqK2TH555+K6CJJQtRbr9XtDmCnjH0AX9Va8J+liIMvDtRsCk2pEs6hKVexR2g7KuDihwt5a9MfprY0fkLXU9ZmFLpoJolN6GXKWWfZx0tHCocwKJSxC22ItYUEjmBUJHFjfYz1xQxlfaLiZsBExq2IPtbkNbLtOwwuGgjTLkH43mYtSzam7+1Bsr3nm5uExBQUozEh9V7N7uvmwZcqdpm0C6vJW63bZEuXtbrV2zpDzhrpYLBWMnY1mjV7JWFtMio7zbWniWFxvHnWm1yGxXmOPXP+L3YV2ysjnNhaZNeMcHPvuL27BMnVMaujljBAYyje4niH4g2ONyh+4PiB4gOODyjWcKxh1gZBNoJjEY4R/BLhF4IDEQ4QPBoEoyxH4+bxrUsHyxwxQlg0WHXqYifVLmo67cKY/UtaXFxBV26TLjuHrkp8BPJTMij1xQejdkgO24nf7dBOCRcbzQuNOR9Qs64GzzrfQa8It2oFAA6Zrga9xEeq1KHmLUHIiCAWInsg1x/MLqkMsItF8QAAAABJRU5ErkJggg==");
        background-size: 30px auto; } }
  @media screen and (-ms-high-contrast: active) {
    .starability-basic {
      width: auto; }
      .starability-basic > input {
        position: static;
        margin-right: 0;
        opacity: 1; }
      .starability-basic .input-no-rate {
        display: none; }
      .starability-basic > label, .starability-basic > .label {
        display: inline;
        float: none;
        width: auto;
        height: auto;
        font-size: 1em;
        color: inherit;
        background: none; }
        .starability-basic > label::before, .starability-basic > .label::before, .starability-basic > label::after, .starability-basic > .label::after {
          display: none; } }

.product-criterion {
  display: flex;
  justify-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem; }
  .product-criterion__name {
    width: auto;
    font-size: 1rem;
    margin-bottom: 0;
    margin-right: 0.25rem; }
  .product-criterion label, .product-criterion .label {
    margin-bottom: 0; }

img[data-lazy] {
  display: none; }

.text-underline {
  text-decoration: underline; }

.cursor-pointer {
  cursor: pointer; }

@media (max-width: 991.98px) {
  .visible--desktop {
    display: none; } }

@media (min-width: 992px) {
  .visible--mobile {
    display: none; } }

.d--none {
  display: none; }

.d--block {
  display: block; }

.full-bleed {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%); }

.d--flex-between {
  display: flex;
  justify-content: space-between; }

.mb--0 {
  margin-bottom: 0; }

.text--center {
  text-align: center; }

.text--right {
  text-align: right; }

.u-a-i-c {
  align-items: center; }

.u-m-l-2 {
  margin-left: 0.5rem; }

.u-link-body {
  color: #212529; }

.u-bor-bot {
  border-bottom: 1px solid #f1f1f1; }
